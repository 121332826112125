import { ActionTypes } from './actionConstants'

export interface IInternetOnline {
    readonly type: ActionTypes.INTERNET_ONLINE
}

export interface IInternetOffline {
    readonly type: ActionTypes.INTERNET_OFFLINE
}

export const internetOnline = (): IInternetOnline => ({ type: ActionTypes.INTERNET_ONLINE })

export const internetOffline = (): IInternetOffline => ({ type: ActionTypes.INTERNET_OFFLINE })
