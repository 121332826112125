import {SagaIterator} from 'redux-saga'
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    take,
} from 'redux-saga/effects'
import {
//    LOCATION_CHANGE,
    push,
} from 'connected-react-router'
import {ApiResponse, ApiResponseTypes} from '../Types/responseTypes'
import {ActionTypes} from '../Actions/actionConstants'
import {addError, IAddErrorAction, removeError} from '../Actions/errorActions'
import {internetConnectionSaga} from "./internetConnection";
import {registerDevice} from "../Api/RegisterDevice";
import {IResetUserData, ISetUserData} from "../Actions/userDataActions";
import {defaultUser, defaultExercises} from "../Reducers/userData";
import {IStoreState} from "../Reducers";
import {IProperties} from "../Types/appTypes";
import {getProperties} from "../Api/GetProperties";
import {setProperties} from "../Actions/propertyActions";
export type SagaLogger = (message?: any, ...optionalParams: any[]) => void

function* errorHandlerSagaCreator<Fn extends (...args: any[]) => any>(fn: Fn, ...args: Parameters<Fn>): SagaIterator {
    try {
        yield call(fn, ...args)
    }
    catch (e) {
        console.log('Generic Saga Error', e)
    }
}

// The root saga is responsible for starting (forking) all other sagas
export function* rootSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorSaga),
        fork(errorHandlerSagaCreator, internetConnectionSaga),
        fork(errorHandlerSagaCreator, registerDeviceTokenSaga, console.log),
        fork(errorHandlerSagaCreator, saveUserData, console.log),
        fork(errorHandlerSagaCreator, resetUserData, console.log),
        fork(errorHandlerSagaCreator, logoutSaga),
        fork(errorHandlerSagaCreator, reloadContentSaga),
    ])
}

export function getLocaleStorageItem(key: string): any {
    return localStorage.getItem(key);
}

export function setLocaleStorageItem(key: string, value: string): void {
    localStorage.setItem(key, value);
}

/*
function* editMeetingSaga(): SagaIterator {
    while (true) {
        const { order, orderDetails }: ISetEditOrder = yield take(ActionTypes.SET_EDIT_ORDER);

        const meetingDuration = orderDetails?.meetingDetails?.meetingDuration ?? 60;
        const serviceCenterIds: any = {};
        const resourceTypeIds: any = {};

        yield put(clearShoppingCart());

        if (orderDetails && orderDetails.bookingDetails && orderDetails.bookingDetails.resources) {
            for (const bookingResource of orderDetails.bookingDetails.resources) {
                resourceTypeIds[bookingResource.resourceTypeId] = true;
                serviceCenterIds[bookingResource.serviceCenterId] = true;
                yield put(addResourceToShoppingCart({
                    selected: true,
                    bookingSuggestion: {
                        endDatetime: orderDetails.meetingDetails ? orderDetails.meetingDetails.startDateTime : '',
                        startDatetime: orderDetails.meetingDetails ? orderDetails.meetingDetails.endDateTime : '',
                    },
                    resource: {
                        id: bookingResource.id,
                        name: bookingResource.name,
                    },
                }));
            }
        }

        if (order) {
            yield put(setResourceFilterValues({
                resourceTypeIds,
                serviceCenterIds,
                meetingDuration,
                startDateTime: moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm'),
            }))
        }
        else {
            yield put(resetResourceFilterValues())
        }

        yield put(clearAvailableRooms());
        yield put(push('/room-finder'));
    }
}
*/

export function* errorSaga(): SagaIterator {
    while (true) {
        // A call to take listens for an action of a specific type and returns the action
        // when one is dispatched from the Redux store
        const error: IAddErrorAction = yield take(ActionTypes.ADD_ERROR);

        // Remove automatic if time is set
        if (error.payload.time) {
            // Use the delay helper to pause execution of the saga
            yield delay(error.payload.time);

            // Put dispatches actions to the Redux store
            yield put(removeError());
        }
    }
}

export function* registerDeviceTokenSaga(logger: SagaLogger): SagaIterator {
    while (true) {
        // const authToken = yield select(getApiToken);
        logger(`listening for ${ActionTypes.REGISTER_DEVICE}`);
        const { token }: {token: string} = yield take(ActionTypes.REGISTER_DEVICE);
        logger(`got token ${token}`);
        if (token) {
            const userData = yield select(getUserData);
            const response: ApiResponse<void> = yield call(
                registerDevice,
                userData.user.uuid,
                token,
                // authToken
            );

            switch (response.type) {
                case ApiResponseTypes.EMPTY_SUCCESS:
                case ApiResponseTypes.SUCCESS:
                    logger(`registered device`);
                    break;
                case ApiResponseTypes.API_ERROR:
                    logger(`server error ${response.displayMessage}`);
                    yield put(addError({text: response.displayMessage}));
                    break;
                default:
                    logger(`network error`);
                    yield put(addError({text: 'Web service not available'}));
            }
        }
    }
}

export function* saveUserData(logger: SagaLogger): SagaIterator {
    while (true) {
        // const authToken = yield select(getApiToken);
        logger(`listening for ${ActionTypes.SET_USER_DATA}`);
        const { user, exercises }: ISetUserData = yield take(ActionTypes.SET_USER_DATA);
        console.log('triggered');

        // @ts-ignore
        setLocaleStorageItem('$_user', JSON.stringify(user));
        // @ts-ignore
        setLocaleStorageItem('$_exercises', JSON.stringify(exercises));
    }
}

export function* resetUserData(logger: SagaLogger): SagaIterator {
    while (true) {
        logger(`listening for ${ActionTypes.RESET_USER_DATA}`);
        const {}: IResetUserData = yield take(ActionTypes.RESET_USER_DATA);

        // @ts-ignore
        setLocaleStorageItem('$_user', JSON.stringify(defaultUser));
        // @ts-ignore
        setLocaleStorageItem('$_exercises', JSON.stringify(defaultExercises));
    }
}

/*
export function* checkUsernameSaga(logger: SagaLogger): SagaIterator {
    while (true) {
        logger(`listening for ${ActionTypes.CHECK_USERNAME}`);
        const { username }: ICheckUsername = yield take(ActionTypes.CHECK_USERNAME);
        logger(`checking username ${username}`);
        const response: ApiResponse<void> = yield call(checkUsername, username);

        switch (response.type) {
            case ApiResponseTypes.EMPTY_SUCCESS:
            case ApiResponseTypes.SUCCESS:
                logger(`valid username ${username}`);
                yield put(validUsername(username));
                yield put(push('/login/password'));
                break;
            case ApiResponseTypes.API_ERROR:
                if (response.responseCode === 404) {
                    logger(`invalid username ${username} (${response.displayMessage})`);
                    yield put(invalidUsername(response.displayMessage, username, response.link?.href));
                    if (typeof response.link === 'object') {
                        yield put(domainExists(response.displayMessage, response.link, username));
                        yield put(push('/login/signup'));
                    } else {
                        yield put(addError({text: response.displayMessage}));
                    }
                } else {
                    logger(`server error ${response.displayMessage}`);
                    yield put(addError({ text: response.displayMessage }));
                }
                break;
            default:
                logger(`network error`);
                yield put(addError({ text: 'Web service not available' }));
        }
    }
}
*/

/*
export function* authenticateSaga(): SagaIterator {
    while (true) {
        const { username, password }: IAuthenticate = yield take(ActionTypes.AUTHENTICATE);
        console.log(username, password);
        yield put(validLogin({
            userId: 1,
            userUid: '2',
            companyId: 3,
            token: '4',
        }));
    }
}
*/

/*
export function* updateUser(): SagaIterator {
    const uid = yield select(getUserUid);
    const token = yield select(getApiToken);
    if (uid && token) {
        const response: ApiResponse<IUser> = yield call(getUser, token, uid);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setUser(response.data));
                break;
            case ApiResponseTypes.API_ERROR:
                if (response.responseCode === 403) {
                    yield put(logout());
                } else {
                    yield put(addError({text: response.displayMessage}));
                }
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}
*/

/*
export function* getUserSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_USER);
        yield call(updateUser);
    }
}
*/

/*
export function getLocale(state: IStoreState) {
    return state.localization.locale ? state.localization.locale : 'en';
}
*/

export function getUserData(state: IStoreState) {
    return state.userData;
}

export function getVersion(state: IStoreState) {
    return state.properties.version;
}

export function getPropertyValues(state: IStoreState) {
    return state.properties;
}

/*
export function getUserUid(state: IStoreState) {
    return state.login.authentication ? state.login.authentication.userUid : null;
}
*/

/*
export function getUserId(state: IStoreState) {
    return state.login.authentication ? state.login.authentication.userId : null;
}
*/

/*
export function getResourceStartDateTime(state: IStoreState) {
    console.log('resourceFilterValues', state.resourceFilterValues.values);

    if (state.resourceFilterValues.values.startDateTime) {
        return state.resourceFilterValues.values.startDateTime.format('YYYY-MM-DD HH:mm');
    }

    return moment().format('YYYY-MM-DD HH:mm');
}
*/

/*
export function getResourceMeetingDuration(state: IStoreState) {
    if (state.resourceFilterValues.values.meetingDuration) {
        return state.resourceFilterValues.values.meetingDuration;
    }

    return 60;
}
*/

/*
export function getResourceEndDateTime(state: IStoreState) {
    return moment(getResourceStartDateTime(state)).add(getResourceMeetingDuration(state), 'minutes').format('YYYY-MM-DD HH:mm');
}
*/

/*
export function getResourceIds(state: IStoreState) {
    const ids = [];

    if (state.shoppingCart.resources) {
        for (const resourceIndex in state.shoppingCart.resources) {
            if (state.shoppingCart.resources[resourceIndex]) {
                ids.push(state.shoppingCart.resources[resourceIndex].resource.id);
            }
        }
    }

    return ids;
}
*/

/*
export function getSearchOptions(state: IStoreState) {
    const filters = state.resourceFilters.filters ? state.resourceFilters.filters : null;
    const values = state.resourceFilterValues ? state.resourceFilterValues.values : null;

    if (!filters || !values) {
        return {};
    }

    // const editOrder = state.editOrder;
    const searchOptions = {
        'startDateTime': moment().format('YYYY-MM-DD HH:mm:00'),
        // 'resourceIds': editOrder.resourceIds,
    };

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < filters.length; i++) {
        const filterElements = filters[i].filterElements;

        // tslint:disable-next-line:prefer-for-of
        for (let x = 0; x < filterElements.length; x++) {
            const filterElement = filterElements[x];

            switch (filterElement.elementType) {
                case "TimeSlider":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = values[filterElement.identifier];
                    } else {
                        searchOptions[filterElement.identifier] = 60;
                    }
                    break;

                case "PlusMinusButton":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = values[filterElement.identifier];
                    } else {
                        searchOptions[filterElement.identifier] = 0;
                    }
                    break;

                case "CheckBox":
                case "MultiSelect":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = [];
                        Object.keys(values[filterElement.identifier]).forEach((key: any) => {
                            if (values[filterElement.identifier] && values[filterElement.identifier][key]) {
                                searchOptions[filterElement.identifier].push(key);
                            }
                        });
                    }
                    break;

                case "DateTimePicker":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = moment(values[filterElement.identifier]).format('YYYY-MM-DD HH:mm:00');
                    }
                    break;

                default: console.warn('Filter element type [' + filterElement.elementType + '] not supported!');
            }
        }
    }

    return searchOptions;
}
*/

/*
export function getEditOrder(state: IStoreState) {
    return state.editOrder;
}
*/

export function* logoutSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.LOGOUT_USER);
        yield put(push('/'));
    }
}

export function* getPropertiesSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_PROPERTIES);
        yield call(updateProperties);
    }
}

export function* updateProperties(): SagaIterator {
    const version = yield select(getVersion);
    const userData = yield select(getUserData);
    const propertyValues = yield select(getPropertyValues);
    const response: ApiResponse<IProperties> = yield call(getProperties, userData, version, propertyValues);

    switch (response.type) {
        case ApiResponseTypes.SUCCESS:
            yield put(setProperties(response.data));
            break;
        case ApiResponseTypes.API_ERROR:
            yield put(addError({text: response.displayMessage}));
            break;
        default:
            yield put(addError({text: 'Web service not available'}));
    }
}

/*
export function* getNotificationCountSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_NOTIFICATION_COUNT);
        yield call(updateNotificationCount);
    }
}

export function* updateNotificationCount(): SagaIterator {
    const token = yield select(getApiToken);
    if (token) {
        const response: ApiResponse<{countNotifications: number}> = yield call(getNotificationCount, token);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setNotificationCount(response.data.countNotifications));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getNotificationsSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_NOTIFICATION_COUNT);
        yield call(updateNotifications);
    }
}

export function* updateNotifications(): SagaIterator {
    const token = yield select(getApiToken);
    if (token) {
        const response: ApiResponse<{notifications: INotification[]}> = yield call(getNotifications, token);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setNotifications(response.data.notifications));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}
*/

/*
function findObjectInArray(items: any[], key: string, value: any): any {
    for (const item in items) {
        if (items[item]) {
            if (items[item][key] === value) {
                return items[item];
            }
        }
    }
}
*/

/*
export function* getNewsSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_NEWS);
        yield call(updateNews);
    }
}

export function* updateNews(): SagaIterator {
    const token = yield select(getApiToken);
    if (token) {
        const response: ApiResponse<{news:INews[]}> = yield call(getNews, token);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setNews(response.data.news));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getAllNewsSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_ALL_NEWS);
        yield call(updateAllNews);
    }
}

export function* updateAllNews(): SagaIterator {
    const token = yield select(getApiToken);
    if (token) {
        const response: ApiResponse<{news:INews[]}> = yield call(getNews, token, 0, 50);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setAllNews(response.data.news));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getAvailableRoomsSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_AVAILABLE_ROOMS);
        yield call(updateAvailableRooms);
    }
}

export function* updateAvailableRooms(): SagaIterator {
    const token = yield select(getApiToken);
    const searchOptions = yield select(getSearchOptions);
    if (token) {
        const response: ApiResponse<{resourceTypes: IResourceType[]}> = yield call(getAvailableRooms, token, searchOptions);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setAvailableRooms(response.data.resourceTypes));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getPredefinedMessagesSaga(): SagaIterator {
    while (true) {
        const { resourceId } = yield take(ActionTypes.GET_PREDEFINED_MESSAGES);
        const token = yield select(getApiToken);
        if (token) {
            const response: ApiResponse<{messages: IPredefinedMessage[]}> = yield call(getPredefinedMessages, token, resourceId);

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                    yield put(setPredefinedMessages(response.data.messages));
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(addError({text: response.displayMessage}));
                    break;
                default:
                    yield put(addError({text: 'Web service not available'}));
            }
        }
    }
}

export function* getCalendarEventSaga(): SagaIterator {
    while (true) {
        const { title, url }: {title: string, url: string} = yield take(ActionTypes.SHOW_CALENDAR_EVENT);
        const token = yield select(getApiToken);
        if (token) {
            const response: ApiResponse<any> = yield call(getCalendarEvent, token, url);

            switch (response.type) {
                case ApiResponseTypes.EMPTY_SUCCESS:
                // case ApiResponseTypes.SUCCESS:
                    yield put(openCalendarEventModal(title, response.text));
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(addError({text: response.displayMessage}));
                    break;
                default:
                    yield put(addError({text: 'Web service not available'}));
            }
        }
    }
}

export function* extendMeetingSaga(): SagaIterator {
    while (true) {
        const { order, endDatetime }: {order: IOrder, endDatetime: string} = yield take(ActionTypes.EXTEND_MEETING);
        const token = yield select(getApiToken);
        if (token && order) {
            yield put(beginTransaction());
            const response: ApiResponse<{orders:{0: IOrderDetails}, calendarImportLinks: IPropertyLink[]}> = yield call(extendMeeting, token, order, endDatetime);

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                    yield fork(reloadHomePage);
                    yield put(openMeetingExtendedModal(response.data.orders[0], response.data.calendarImportLinks));
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
                    break;
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be extended',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
            }
            yield put(endTransaction());
        }
    }
}

export function* requestAssistanceSaga(): SagaIterator {
    while (true) {
        const { order, resource, message, messageId }: {order: IOrder, resource: any, message: string, messageId: number | null} = yield take(ActionTypes.REQUEST_ASSISTANCE);
        const token = yield select(getApiToken);
        if (token && order) {
            const response: ApiResponse<any> = yield call(requestAssistance, token, order, resource, message, messageId);

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield put(openSuccessModal(
                        'Your message is received',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
                    console.log('SUCESS!', response);
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
                    break;
                default:
                    yield put(openActionFailedModal(
                        'Your message was not delivered',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
            }
        }
    }
}

export function* endMeetingSaga(): SagaIterator {
    while (true) {
        const { order }: {order: IOrder} = yield take(ActionTypes.END_MEETING);
        const token = yield select(getApiToken);
        if (token && order) {
            const response: ApiResponse<any> = yield call(endMeeting, token, order);

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield fork(reloadHomePage);
                    yield put(openMeetingEndedModal());
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
                    break;
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be ended',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
            }
        }
    }
}

export function* startMeetingSaga(): SagaIterator {
    while (true) {
        const { order }: {order: IOrder} = yield take(ActionTypes.START_MEETING);
        const token = yield select(getApiToken);
        if (token && order) {
            const response: ApiResponse<any> = yield call(startMeeting, token, order);

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield fork(reloadHomePage);
                    yield put(openMeetingStartedModal());
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
                    break;
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be started',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
            }
        }
    }
}

export function* deleteMeetingSaga(): SagaIterator {
    while (true) {
        const { order }: {order: IOrder} = yield take(ActionTypes.DELETE_MEETING);
        const token = yield select(getApiToken);
        if (token && order) {
            const response: ApiResponse<any> = yield call(deleteMeeting, token, order);

            switch (response.type) {
                case ApiResponseTypes.SUCCESS:
                case ApiResponseTypes.EMPTY_SUCCESS:
                    yield fork(reloadHomePage);
                    yield put(openMeetingDeletedModal());
                    break;
                case ApiResponseTypes.API_ERROR:
                    yield put(openActionFailedModal(
                        response.displayMessage,
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
                    break;
                default:
                    yield put(openActionFailedModal(
                        'Your meeting could not be deleted',
                        moment(order.meetingDetails?.startDateTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(order.meetingDetails?.endDateTime).format('HH:mm'),
                        order.id,
                        order.meetingDetails ? order.meetingDetails.meetingName : ''
                    ));
            }
        }
    }
}

export function* updateResourceFilters(): SagaIterator {
    const token = yield select(getApiToken);
    if (token) {
        const response: ApiResponse<{filterGroups: IResourceFilter[]}> = yield call(getResourceFilters, token);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setResourceFilters(response.data.filterGroups));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getOrderDetailsSaga(): SagaIterator {
    while (true) {
        const { orderId }: { orderId: number } = yield take(ActionTypes.GET_MEETING_DETAILS);
        yield call(updateOrderDetails, orderId);
    }
}

export function* updateOrderDetails(orderId: number): SagaIterator {
    const token = yield select(getApiToken);
    // const orderId = yield select(getOrderDetailsModalOrderId);
    if (token && orderId) {
        const response: ApiResponse<{orders: IOrderDetails[]}> = yield call(getOrderDetails, token, orderId);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                if (response.data.orders.length > 0) {
                    yield put(setMeetingDetails(response.data.orders[0]));
                } else {
                    yield put(setMeetingDetails(null));
                }
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getCanteensSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_CANTEENS);
        yield call(updateCanteens);
    }
}

export function* updateCanteens(): SagaIterator {
    const token = yield select(getApiToken);
    if (token) {
        const response: Unpromisify<ReturnType<typeof getCanteens>> = yield call(getCanteens, token);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setCanteens(response.data.kitchens));
                if (response.data.kitchens.length > 0) {
                    // @ts-ignore
                    const storedCanteenId = getLocaleStorageItem('currentCanteenId');
                    // tslint:disable-next-line:radix
                    const storedCanteen: null | ICanteen = findObjectInArray(response.data.kitchens, 'id', parseInt(storedCanteenId));

                    if (storedCanteen) {
                        yield put(setCurrentCanteen(storedCanteen));
                        yield put(getTodaysMenu(storedCanteen.id));
                    } else {
                        yield put(setCurrentCanteen(response.data.kitchens[0]));
                        yield put(getTodaysMenu(response.data.kitchens[0].id));
                    }
                } else {
                    yield put(setCurrentCanteen(null));
                    yield put(setTodaysMenu({
                        menues: [],
                    }));
                }
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* updatedCurrentCanteen(): SagaIterator {
    while (true) {
        yield take(ActionTypes.SET_CURRENT_CANTEEN);
        const canteen = yield select(getCurrentCanteen);
        if (canteen) {
            setLocaleStorageItem('currentCanteenId', canteen.id);
        }
        yield call(updateTodaysMenu);
        yield call(updateWeeksMenu);
    }
}

export function* getTodaysMenuSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_TODAYS_MENU);
        yield call(updateTodaysMenu);
    }
}

export function* updateTodaysMenu(): SagaIterator {
    const token = yield select(getApiToken);
    const canteen = yield select(getCurrentCanteen);
    if (token && canteen) {
        const response: ApiResponse<ITodaysMenu> = yield call(getTodaysMenuApi, token, canteen.id);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setTodaysMenu(response.data));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getWeeksMenuSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_WEEKS_MENU);
        yield call(updateWeeksMenu);
    }
}

export function* updateWeeksMenu(): SagaIterator {
    const token = yield select(getApiToken);
    const canteen = yield select(getCurrentCanteen);
    if (token && canteen) {
        const response: ApiResponse<IWeeksMenu> = yield call(getWeeksMenuApi, token, canteen.id);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setWeeksMenu(response.data));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}

export function* getTranslationsSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_TRANSLATIONS);
        yield call(updateTranslationsSaga);
    }
}

export function* updateTranslationsSaga(): SagaIterator {
    const token = yield select(getApiToken);
    const locale = yield select(getLocale);
    if (token && locale) {
        const response: ApiResponse<any> = yield call(getTranslations, token, locale);

        switch (response.type) {
            case ApiResponseTypes.SUCCESS:
                yield put(setTranslations(locale, response.data.properties));
                break;
            case ApiResponseTypes.API_ERROR:
                yield put(addError({text: response.displayMessage}));
                break;
            default:
                yield put(addError({text: 'Web service not available'}));
        }
    }
}
*/

export function getPropertyUpdateTimer(state: IStoreState) {
    return (state.properties.refreshContentInMinutes ? state.properties.refreshContentInMinutes : 5) * 60 * 1000;
}

function* reloadContentSaga() {
    while (true) {
        yield call(updateProperties);
        const time = yield select(getPropertyUpdateTimer);
        yield delay(time);
    }
}

/*
function* reloadHomePage() {
    yield call(updateCanteens);
    yield call(updateTodaysMenu);
    yield call(updateWeeksMenu);
    yield call(updateAllNews);
}

function* refreshContentOnEnterSaga(logger: SagaLogger) {
    while (true) {
        // Listen for navigation changes and when modals that fetch data open (since they don't cause navigation)
        logger(`Listening for navigation actions...`);
        const action: Action = yield take([
            LOCATION_CHANGE,
            ActionTypes.OPEN_RESOURCE_FILTERS_MODAL,
            ActionTypes.OPEN_WEEK_MENU_MODAL,
            ActionTypes.OPEN_ALL_NEWS_MODAL,
            ActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL,
        ]);
        const currentCanteen = yield select(getCurrentCanteen);
        switch (action.type) {
            case ActionTypes.OPEN_RESOURCE_FILTERS_MODAL:
                logger("Opened resource filter modal [refresh filters]");
                yield put(getResourceFiltersAction());
                break;
            case ActionTypes.OPEN_WEEK_MENU_MODAL:
                logger("Opened week menu modal [refresh content]");
                if (currentCanteen) {
                    yield put(getWeeksMenu(currentCanteen.id));
                }
                break;
            case ActionTypes.OPEN_ALL_NEWS_MODAL:
                logger("Opened all news modal [refresh content]");
                yield put(getAllNews());
                break;
            case ActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL:
                logger("Opened request assistance modal [loading messages]");
                if (action.meetingDetails && action.meetingDetails.bookingDetails && action.meetingDetails.bookingDetails.resources) {
                    yield put(getPredefinedMessagesAction(action.meetingDetails.bookingDetails.resources[0].id));
                }
                break;
            case LOCATION_CHANGE:
                const { payload: { location: { pathname } } } = action;
                switch (pathname) {
                    case '/':
                        // NOTE: We dispatch actions instead of calling the sagas directly
                        // By going via the Redux store we can follow the action in Redux DevTools browser plugin
                        // This could also be used to avoid splitting all the load sagas in two as they are above
                        logger(`Navigated to '/' [refresh landing page content]`);
                        yield put(getCanteensAction());
                        if (currentCanteen) {
                            yield put(getWeeksMenu(currentCanteen.id));
                        }
                        break;
                    case '/my-meetings':
                        logger(`Navigated to '/my-meetings' [refresh all upcoming meetings]`);
                        break;
                    // TODO: Add other locations that need background refresh when entering
                    default:
                        logger(`No handling defined for path '${pathname}' [not refreshing content]`);
                }
                break;
            default:
                logger(`Action type not recognized: '${action.type}' [not refreshing content]`);
                break;
        }
    }
}
*/
