import React from 'react'
import Screen from '../Components/Screen';
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import {__, findCompleted} from "../helpers";
import {resetUserData} from "../Actions/userDataActions";
import {IProperties, IUserData} from "../Types/appTypes";
import Gardennosky from '../assets/images/Gardennosky.png'
import wavesSky from '../assets/images/wavesSky.png'
import AssetHelper from "../Helpers/AssetHelper";
import Image from "../Components/Image";

export interface IOwnProps {
    //
}

interface IStateProps {
    userData: IUserData
    properties: IProperties
}

interface IDispatchProps {
    reset: any
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const Home = (props: Props) => {
    const {reset, userData, properties} = props;

    const completed = findCompleted(properties.chapters, userData.exercises, false, false, false);

    const nothing = () => {
        if (completed.length !== 0) {
            return
        }

        return (
            <div style={{
                display: 'table-cell',
                height: 'calc(100vh - 150px)',
                verticalAlign: 'middle',
                padding: 20,
            }}>
                <div style={{
                    width: 'calc(100% - 20px)',
                    margin: '0 auto',
                    background: 'white',
                    padding: '7px 20px',
                    border: '5px solid white',
                    borderRadius: 25,
                    opacity: 0.9,
                }}>
                    <div style={{
                        color: '#696969',
                        textAlign: 'center',
                    }}>
                        <div style={{
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}>
                            <p>This is an overview of your answers.</p>
                        </div>
                        <div style={{
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}>
                            <hr style={{
                                borderColor: 'rgba(0, 0, 0, 0.87)',
                                borderWidth: '1px 0px 0px;',
                            }} />
                        </div>
                        <div className="makeStyles-bold-61" style={{
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            fontWeight: 'bold',
                        }}>
                            <p>Come back later when you are further in your journey.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const chapters = () => {
        return (
            <div>
                {completed.map(({chapter, exercises}, chapterKey) => {
                    return (
                        <div key={chapterKey} style={{padding: '7px 20px'}}>
                            <p style={{
                                color: '#707070',
                                margin: 0,
                                fontSize: 20,
                                marginTop: 30,
                            }}>{chapter.category}:</p>
                            <p style={{
                                color: '#707070',
                                margin: 0,
                                fontSize: 20,
                                marginTop: 7,
                                fontWeight: 'bolder',
                            }}>{chapter.name}</p>

                            <hr style={{
                                borderWidth: 1,
                                borderColor: '#707070',
                                borderBottomWidth: 0,
                                marginTop: 20,
                            }} />

                            {exercises.map(({exercise, finished, steps}, exerciseKey) => {
                                return (
                                    <div key={exerciseKey} style={{marginTop: 20}}>
                                        <p style={{
                                            color: '#707070',
                                            margin: 0,
                                            fontSize: 20,
                                            marginTop: 20,
                                        }}>Exercise: {exercise.name}</p>

                                        <div style={{borderLeft: '1px solid #707070', paddingLeft: 20, marginTop: 20}}>
                                            {steps.map(({step, readableValue}, stepKey) => {
                                                return (
                                                    <div key={stepKey} style={{ marginTop: stepKey !== 0 ? 20 : 0 }}>
                                                        <p style={{
                                                            fontSize: 16,
                                                            letterSpacing: 0,
                                                            color: '#707070',
                                                            fontWeight: 'bolder',
                                                        }}>{step.title}</p>
                                                        <p style={{
                                                            fontSize: 16,
                                                            letterSpacing: 0,
                                                            color: '#707070',
                                                        }} dangerouslySetInnerHTML={{ __html: (typeof readableValue === 'string' ? readableValue.replace("\n", '<br />') : '')}} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div>
            <Screen name="MyOverview" showNavBar={true} style={{
                backgroundImage: AssetHelper.cssUrl(wavesSky),
                backgroundAttachment: 'fixed',
                minHeight: '100vh',
                paddingBottom: 100,
            }}>
                <Image style={{
                    position: 'fixed',
                    bottom: 40,
                    width: '100%',
                    pointerEvents: 'none',
                }}>{Gardennosky}</Image>

                {chapters()}
                {nothing()}

                <button onClick={reset} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    display: 'none',
                }}>Reset my data</button>
            </Screen>
        </div>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({userData, properties}) => ({
    userData,
    properties,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    reset: () => {
        console.log('test');

        /*
        dispatch(setUserData({
            user: defaultUser,
            exercises: []
        }))
        */

        return dispatch(resetUserData())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)
