import React, {useEffect} from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import {closeSnackbar} from "../Actions/snackbarActions";

export interface IOwnProps {
    //
}

interface IStateProps {
    message: string
    open: boolean
}

interface IDispatchProps {
    close: () => any
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 7,
        },
    }),
);

const Element: React.FunctionComponent<Props> = (props) => {
    const {message, open, close} = props;

    useEffect(() => {
        console.log('useEffect', open, message, close);
    });

    const classes = useStyles();

    const rootAttributes = {
        className: classes.root,
        open,
        autoHideDuration: 3000,
        onClose: () => {
            close();
        },
        onClick: () => {
            close();
        },
        // TransitionComponent: state.Transition,
        message,
        // key: 'test',
    };

    return (
        <Snackbar {...rootAttributes} />
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ snackbar }) => ({
    message: snackbar.message,
    open: snackbar.open,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    close: () => {
        return dispatch(closeSnackbar());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Element);
