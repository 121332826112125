import React from 'react';
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux";
import Drawer from '@material-ui/core/Drawer';
import {createStyles, makeStyles, Theme, Box} from "@material-ui/core";
import { IStoreState } from '../Reducers'
import ModalHeader from "./ModalHeader";
import {Roboto} from "shared/theme";
import 'date-fns';
import ErrorHandlerModal from './ErrorHandlerModal';
import {closeHelpModal, ICloseHelpModal} from "../Actions/helpModalActions";
import Card from "./Card";
import {Localized} from "@fluent/react";
import TextField from "./TextField";
// import {IHelpScreen} from "../Types/appTypes";
import Button from "shared/Components/Button";

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
//    screens: IHelpScreen[] | null
}

// These re the props used to dispatch actions from the component
interface IDispatchProps {
    hideHelpModal: () => ICloseHelpModal,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ helpModal }) => ({
    isDrawerOpen: helpModal.open,
//    screens: helpModal.screens,
});
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideHelpModal: () => dispatch(closeHelpModal()),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            minWidth: '100vw',
            overflow: 'auto',
            padding: '10px 15px 90px 15px',
            width: '100vw',
        },
        innerCard: {
            padding: '12px 14px',
        },
        root: {
            position: 'fixed',
        },
        sittingArrangementFormControl: {
            display: 'block',
            marginBottom: 20,
        },
        sittingArrangementSelect: {
            // border: '1px solid #AAAAAA',
            width: '100%',
        },
        sittingArrangementSelectInput: {
            color: '#505050',
            width: '100%',
            padding: '10px 15px !important',
            display: 'block',
            fontSize: 14,
        },
        dateContainer: {
            borderBottom: "1px solid #9e9e9e",
            position: "relative",
            "&:before": {
                content: '""',
                position: "absolute",
                bottom: "-1px",
                left: 0,
                right: 0,
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                pointerEvents: "none"
            }
        }
    }),
);

const CheckoutContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideHelpModal, classes } = props;

    return (
        <div role="presentation" className={classes.container}>
            <ModalHeader fixed={true} title="Complete booking" onClose={hideHelpModal} />


            <Localized id="helpModal-name" attrs={{ label: true }}>
                <TextField
                    autoFocus={true}
                    label={
                        <span style={{
                            color: 'rgb(150, 150, 150)',
                            fontSize: 17,
                            letterSpacing: '-0.25px',
                        }}>Meeting name</span>
                    }
                    value={'meetingName'}
                    onChange={() => {}}
                />
            </Localized>
            <Box display="flex" marginTop={2} className={classes.dateContainer}>
                <div style={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}>
                    <p style={{
                        color: '#969696',
                        fontFamily: Roboto,
                        fontSize: 13,
                        letterSpacing: '-0.25px',
                        lineHeight: '4px',
                    }}>From</p>
                    <p style={{
                        color: '#505050',
                        fontFamily: Roboto,
                        fontSize: 15,
                        letterSpacing: '-0.25px',
                        lineHeight: '16px',
                    }}>
                        {'test'}
                    </p>
                </div>
                <div style={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}>
                    <p style={{
                        color: '#969696',
                        fontFamily: Roboto,
                        fontSize: 13,
                        letterSpacing: '-0.25px',
                        lineHeight: '4px',
                    }}>To</p>
                    <p style={{
                        color: '#505050',
                        fontFamily: Roboto,
                        fontSize: 15,
                        letterSpacing: '-0.25px',
                        lineHeight: '16px',
                    }}>
                        {'test'}
                    </p>
                </div>
            </Box>

            <p style={{
                color: '#505050',
                fontFamily: Roboto,
                fontSize: 18,
                fontWeight: 900,
                letterSpacing: '-0.12px',
                lineHeight: '21px',
            }}>More options</p>
            <Card marginTop={20}>
                <div className={classes.innerCard}>

                </div>
            </Card>

            <Button disabled={false} wide={true} floating={'bottom'} onClick={() => {
                closeHelpModal();
            }}>Next</Button>
        </div>
    );
}

const HelpModal = (props: IProps) => {
    const { isDrawerOpen, hideHelpModal } = props;
    const classes = useStyles();
    const rootAttributes = {
        onClose: hideHelpModal,
        open: isDrawerOpen,
        className: classes.root,
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideHelpModal}>
                    <CheckoutContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpModal)
