import { ApiResponseTypes, ApiResponse } from '../Types/responseTypes'
import ApiHelper from '../Helpers/ApiHelper';
import 'whatwg-fetch';

const requestTimeoutInMillis = 10000;

const offline = () => {
    return navigator && navigator.onLine !== undefined && !navigator.onLine
};

interface IHeadersArg {
    version?: string
    contentType?: string
}

const headerArgToKey = (key: keyof IHeadersArg) => {
    switch(key) {
        case "contentType": 
            return "Content-Type";
        case "version":
            return "X-Version";
    }
}

export const getHeaders = (args: IHeadersArg = {}) => {
    const inputValue: Record<string, string> = {
        [headerArgToKey("contentType")]: 'application/json'
    };
    return Object.keys(args).reduce((reduction, key: keyof IHeadersArg) => {
        const currentArg = args[key];
        if (currentArg) {
            return {
                ...reduction,
                [headerArgToKey(key)]: currentArg
            }
        }
        return reduction
    }, inputValue);
}

const isJsonContent = (response: Response) =>
    response.headers.get('Content-Type') === 'application/json'

async function handleJsonResponse<T>(response: Response): Promise<ApiResponse<T>> {
    const data = await response.json()

    if (response.ok) {
        return {
            type: ApiResponseTypes.SUCCESS,
            responseCode: response.status,
            data
        }
    } else {
        return {
            type: ApiResponseTypes.API_ERROR,
            responseCode: response.status,
            ...data
        }
    }
}

export async function http<T>(request: Request): Promise<ApiResponse<T>> {
    // @ts-ignore
    const isCordova = typeof cordova !== 'undefined';
    request.headers.set('X-Client-Type', isCordova ? 'mobile-app' : 'mobile-web')
    const abortControl = new AbortController()
    const timeout = setTimeout(() => abortControl.abort(), requestTimeoutInMillis)

    try {
        const response = await fetch(request, { signal: abortControl.signal })

        // console.log('response', response.text());

        if (isJsonContent(response)) {
            return handleJsonResponse<T>(response)
        } else if (response.ok) {
            const text = await response.text()

            return {
                type: ApiResponseTypes.EMPTY_SUCCESS,
                responseCode: response.status,
                text,
            }
        } else {
            return {
                type: ApiResponseTypes.PROTOCOL_ERROR,
                responseCode: response.status
            }
        }
    } catch (error) {
        if (offline()) {
            return {
                type: ApiResponseTypes.NETWORK_ERROR,
                message: 'Internet is down',
                error
            }
        } else {
            return {
                type: ApiResponseTypes.NETWORK_ERROR,
                message: 'Web service not available',
                error
            }
        }
    } finally {
        clearTimeout(timeout)
    }
}

export function get<T>(
    path: string,
    body: any = {},
    args: RequestInit = { method: 'get', headers: getHeaders() }
): Promise<ApiResponse<T>> {
    return http<T>(new Request(ApiHelper.path(path), args));
};

export function post<T>(
    path: string,
    body: any = {},
    args: RequestInit = { method: 'post', body: JSON.stringify(body), headers: getHeaders() }
): Promise<ApiResponse<T>> {
    return http<T>(new Request(ApiHelper.path(path), args));
};

export function put<T>(
    path: string,
    body: any = {},
    args: RequestInit = { method: 'put', body: JSON.stringify(body), headers: getHeaders() }
): Promise<ApiResponse<T>> {
    return http<T>(new Request(ApiHelper.path(path), args));
};
