import { IError } from '../Types/appTypes'
import { ActionTypes } from './actionConstants'

export interface IAddErrorAction {
    readonly type: ActionTypes.ADD_ERROR,
    readonly payload: IError,
}

export interface IRemoveErrorAction {
    readonly type: ActionTypes.REMOVE_ERROR
}

export const addError = (payload: IError): IAddErrorAction => ({
    payload,
    type: ActionTypes.ADD_ERROR,
});

export const removeError = (): IRemoveErrorAction => ({
    type: ActionTypes.REMOVE_ERROR
});
