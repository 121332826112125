import { ActionTypes } from './actionConstants'
// import {IHelpScreen} from "../Types/appTypes";

export interface IOpenHelpModal {
    readonly type: ActionTypes.OPEN_HELP_MODAL
//    readonly screens: IHelpScreen[] | null
}

export interface ICloseHelpModal {
    readonly type: ActionTypes.CLOSE_HELP_MODAL
}

/*
export const openHelpModal = (screens: IHelpScreen[]): IOpenHelpModal => ({
    type: ActionTypes.OPEN_HELP_MODAL,
    screens,
})
*/

export const closeHelpModal = (): ICloseHelpModal => ({ type: ActionTypes.CLOSE_HELP_MODAL })
