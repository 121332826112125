import React, {CSSProperties} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
// import {Slide} from '@material-ui/core';
import {IStoreState} from "../Reducers";
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {push, RouterState} from "connected-react-router";
import AssetHelper from "../Helpers/AssetHelper";

export interface IOwnProps {
    children?: any[] | any
    name: string
    showNavBar?: boolean
    style?: CSSProperties
    containerBackground?: string
}

interface IStateProps {
    router: RouterState<any>
}

interface IDispatchProps {
    go: (uri: string) => any,
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({router}) => ({
    router,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    go: (uri) => {
        return dispatch(push(uri))
    },
});

const Screen = (props: IProps) => {
    const {go, router, style} = props;

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            container: {
                height: '100vh',
                display: 'block',
                paddingBottom: '100px',
                overflow: 'auto',
            },
            root: {
                // backgroundColor: 'white',
                display: 'block',
                position: 'relative',
                minHeight: '100vh',
            },
            navigation: {
                width: '100%',
                position: 'fixed',
                bottom: 0,
            },
            navbar: {
                background: 'white',
                position: 'fixed',
                bottom: 0,
                width: '100%',
                left: 0,
                zIndex: 99999999,
            },
            navbarElement: {
                width: '50%',
                display: 'inline-block',
                textAlign: 'center',
                padding: 0,
                borderTop: '5px solid #E4E4E4',
            },
            activeNavbarElement: {
                width: '50%',
                display: 'inline-block',
                textAlign: 'center',
                padding: 0,
                borderTop: '5px solid #F2A7A1',
            },
            navbarElementText: {
                display: 'block',
                color: '#707070',
            },
        }),
    );

    const classes = useStyles();

    const navbar = () => {
        if (!props.showNavBar) {
            return;
        }

        return (
            <div className={classes.navbar}>
                <div className={router.location.pathname === '/' ? classes.activeNavbarElement : classes.navbarElement}
                     onClick={() => {
                         go('/')
                     }}>
                    <p>Journey</p>
                </div>

                <div
                    className={router.location.pathname === '/my-overview' ? classes.activeNavbarElement : classes.navbarElement}
                    onClick={() => {
                        go('/my-overview')
                    }}>
                    <p>My Overview</p>
                </div>
            </div>
        )
    };

    // Add additional classes
    const rootClasses = classes.root + ' ' + [
        'Screen',
        props.name + '-Screen',
        props.showNavBar ? 'Screen-HasNavBar' : null,
    ].filter((name: string) => {
        return name != null;
    }).join(' ');

    // <Slide direction="left" in={true}>
    return (
        <div className={rootClasses} style={style}>
            {navbar()}

            <div className={classes.container} style={{
                backgroundImage: props.containerBackground ? AssetHelper.cssUrl(props.containerBackground) : undefined,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            }}>
                {props.children}
            </div>
        </div>
    )
    // </Slide>
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
