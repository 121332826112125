import React, {CSSProperties} from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AssetHelper from "../Helpers/AssetHelper";

interface IProps {
    display?: any
    children?: any[] | any
    height?: number | string
    width?: number | string
    left?: string | number | undefined
    marginBottom?: string | number | undefined
    marginLeft?: string | number | undefined
    marginRight?: string | number | undefined
    marginTop?: string | number | undefined
    position?: "absolute" | undefined
    top?: string | number | undefined
    style?: CSSProperties
    className?: string
}

export default function(props: IProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: props.display,
                height: props.height,
                width: props.width,
                left: props.left,
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                marginTop: props.marginTop,
                position: props.position,
                top: props.top,
            }
        }),
    );

    const classes = useStyles();

    return (
        <img style={props.style} src={AssetHelper.url(props.children)} className={classes.root + ' ' + props.className} />
    )
}
