import {Reducer} from 'redux'
import {Action, ActionTypes} from 'mobile/Actions/actionConstants'
import {IUserData} from "../Types/appTypes";
import {getLocaleStorageItem} from "../Sagas/sagas";

export const defaultUser = {
    uuid: null,
    token: null,
    firstname: null,
    finishedIntro: false,
};
export const defaultExercises = [];

const storedUser = getLocaleStorageItem('$_user');
const storedExercises = getLocaleStorageItem('$_exercises');

const initialState: IUserData = {
    user: typeof storedUser === 'string' ? JSON.parse(storedUser) : defaultUser,
    exercises: typeof storedExercises === 'string' ? JSON.parse(storedExercises) : defaultExercises,
};

const userDataReducer: Reducer<IUserData, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER_DATA:
            return {
                ...state,
                user: action.user,
                exercises: action.exercises,
            };
        case ActionTypes.RESET_USER_DATA:
            return {
                ...state,
                user: {
                    uuid: null,
                    token: null,
                    firstname: null,
                    finishedIntro: false
                },
                exercises: [],
            };
        default:
            return state
    }
};

export default userDataReducer
