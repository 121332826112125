import React, {useEffect} from 'react';
import {MapStateToProps, MapDispatchToPropsFunction, connect} from "react-redux";
import {createStyles, Drawer, makeStyles, Theme} from "@material-ui/core";
import {IStoreState} from '../Reducers'
import 'date-fns';
import {IExerciseContent, IExerciseStep, IProperties, IUserData} from "../Types/appTypes";
import {__} from "../helpers";
import Image from "./Image";
import AssetHelper from "../Helpers/AssetHelper";
import wave1 from "../assets/images/Wave1.png";
import wave2 from "../assets/images/Wave2.png";
import wave3 from "../assets/images/Wave3.png";
import sky from '../assets/images/Sky.png'
import ReactAudioPlayer from "react-audio-player";

export interface IOwnProps {
    step: IExerciseStep
    next: any
    previous: any
    open: boolean
    isCurrent: boolean
    saveValue: any
    close: any
    stepNo: number
    stepAmount: number
}

interface IStateProps {
    properties: IProperties
    userData: IUserData
}

interface IDispatchProps {
    //
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({properties, userData}) => ({
    properties,
    userData
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (/* dispatch */) => ({
    //
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            //
        },
        contentWrapper: {
            background: 'white',
            width: '100vw',
            height: '100vh',
            display: 'block',
            padding: 12,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            lineHeight: 1.4,
            overflowY: 'auto',
        },
        waves: {
            width: '100vw',
            height: '100%',
            maxHeight: '100%',
            display: 'block',
            padding: 12,
            position: 'relative',
            backgroundImage: AssetHelper.cssUrl(sky),
            overflow: 'hidden',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },
        wave1: {
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '50vh', // '200px',
            backgroundImage: AssetHelper.cssUrl(wave1),
            backgroundSize: 'cover', // '1000px 200px', // '300px',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',

            // wave 1 specific
            animation: 'animate 40s linear infinite',
            zIndex: 1003,
            opacity: 1, // 1,
            animationDelay: '0s',
            bottom: 0, // 'calc(10% + 10px)',
        },
        wave2: {
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '50vh', // '200px',
            backgroundImage: AssetHelper.cssUrl(wave2),
            backgroundSize: 'cover', // '1000px 200px', // '300px',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',

            // wave 2 specific
            animation: 'animate2 40s linear infinite',
            zIndex: 1002,
            opacity: 1, // 0.5,
            animationDelay: '0s', // '-5s',
            bottom: 0, // 'calc(10% + 10px)',
        },
        wave3: {
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '50vh', // '200px',
            backgroundImage: AssetHelper.cssUrl(wave3),
            backgroundSize: 'cover', // '1000px 200px', // '300px',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',

            // wave 3 specific
            animation: 'animate 40s linear infinite',
            zIndex: 1001,
            opacity: 1, // 0.2,
            animationDelay: '0s', // '-2s',
            bottom: 0, // 'calc(10% + 5px)',
        },
        whitesand: {
            display: 'none',

            position: 'absolute',
            left: 0,
            width: '100%',
            height: 'calc(10% + 12px)',
            zIndex: 996,
            opacity: 1,
            bottom: 0,
            backgroundColor: 'white',
        },
        bold: {
            fontWeight: 'bold',
        },
        underline: {
            textDecoration: 'underline',
        },
        italic: {
            fontStyle: 'italic',
        },
        sizeSmall: {
            fontSize: 12,
        },
        sizeNormal: {
            fontSize: 22,
        },
        sizeBig: {
            fontSize: 28,
        },
        alignLeft: {
            textAlign: 'left',
        },
        alignCenter: {
            textAlign: 'center',
        },
        alignRight: {
            textAlign: 'right',
        },
        title: {
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        button: {
            background: 'white',
            color: '#707070',
            padding: '12px 40px',
            display: 'block',
            borderRadius: 7,
            textAlign: 'center',
            cursor: 'pointer',
            margin: '0 auto',
            border: 0,
            '&:disabled': {
                background: 'gray',
            },
            boxShadow: '5px 10px 10px #00000029',
        },
        primaryButton: {
            background: '#90ED9B',
            color: 'white',
            padding: '12px 40px',
            display: 'block',
            borderRadius: 7,
            textAlign: 'center',
            cursor: 'pointer',
            margin: '0 auto',
            border: 0,
            '&:disabled': {
                background: '#707070',
            },
            boxShadow: '5px 10px 10px #00000029',
        },
        selectOption: {
            background: '#FFFFFF',
            color: '#707070',
            border: 0,
            padding: '10px 20px',
            display: 'block',
            borderRadius: 7,
            textAlign: 'center',
            cursor: 'pointer',
            marginBottom: 10,
            width: '100%',
            borderWidth: '1px',
            outline: 0,
            '&:hover': {
                background: '#E4E4E4',
            },
        },
        imageButtonWrapper: {
            maxHeight: 200,
            maxWidth: '100%',
        },
        countdown: {
            textAlign: 'center',
        },
        content: {
            color: '#696969',
        },
        verticalAlignMiddle: {
            display: 'table-cell',
            width: '100vw',
            height: 'calc(100vh - 100px)',
            verticalAlign: 'middle',
        },
        verticalAlignBottom: {
            display: 'table-cell',
            width: '100vw',
            height: 'calc(100vh - 100px)',
            verticalAlign: 'bottom',
            paddingBottom: 50,
        },
        verticalAlignTop: {
            display: 'table-cell',
            width: '100vw',
            height: 'calc(100vh - 100px)',
            verticalAlign: 'top',
        },
        subContent: {
            width: 'calc(100% - 20px)',
            margin: '0 auto',
            background: 'white',
            padding: '7px 20px',
            border: '5px solid white',
            borderRadius: 25,
            opacity: 0.9,
        },
        paddingX: {
            paddingRight: 25,
            paddingLeft: 25,
        },
        input: {
            background: 'white',
            borderRadius: 7,
            '&::placeholder': {
                color: '#E4E4E4',
            },
            width: '100%',
            padding: 10,
            border: 0,
            marginBottom: 10,
            marginTop: 10,
        },
        paddingTop25: {
            paddingTop: 25,
        },
    }),
);

const ExerciseStep = (props: IProps) => {
    const {stepAmount, step, open, next, close, stepNo, isCurrent, saveValue, properties, userData} = props;
    const classes = useStyles();

    const [inputValue, setInputValue] = React.useState('');

    const setValue = (value: string | number | null) => {
        saveValue(value);
        next(stepNo);
    }

    const content = (currentContent?: IExerciseContent[]): any => {
        if (currentContent === undefined && 'content' in step) {
            currentContent = step.content
        }

        return (
            <div className={classes.content}>
                {currentContent && typeof currentContent !== 'undefined' ? currentContent.map((contentRow, key) => {
                    let className = '';

                    if (contentRow.bold) {
                        className += classes.bold + ' ';
                    }

                    if (contentRow.underline) {
                        className += classes.underline + ' ';
                    }

                    if (contentRow.italic) {
                        className += classes.italic + ' ';
                    }

                    if (contentRow.size === 'big') {
                        className += classes.sizeBig + ' ';
                    }

                    if (contentRow.size === 'normal') {
                        className += classes.sizeNormal + ' ';
                    }

                    if (contentRow.size === 'small') {
                        className += classes.sizeSmall + ' ';
                    }

                    if (contentRow.align === 'left') {
                        className += classes.alignLeft + ' ';
                    }

                    if (contentRow.align === 'center') {
                        className += classes.alignCenter + ' ';
                    }

                    if (contentRow.align === 'right') {
                        className += classes.alignRight + ' ';
                    }

                    if (contentRow.verticalAlign === 'middle') {
                        className += classes.verticalAlignMiddle + ' ';
                    }

                    if (contentRow.verticalAlign === 'top') {
                        className += classes.verticalAlignTop + ' ';

                        if (step.skipable || step.hideProcess !== false) {
                            className += classes.paddingTop25 + ' ';
                        }
                    }

                    if (contentRow.verticalAlign === 'bottom') {
                        className += classes.verticalAlignBottom + ' ';
                    }

                    if (contentRow.paddingX === true) {
                        className += classes.paddingX + ' ';
                    }

                    const subContent = () => {
                        if (typeof contentRow.content !== 'object') {
                            return ''
                        }

                        let subContentClass = classes.subContent;

                        if (contentRow.styleSubContent === false) {
                            subContentClass = '';
                        }

                        return (
                            <div className={subContentClass} style={contentRow.subContentStyle}>
                                {content(contentRow.content)}
                            </div>
                        )
                    }

                    if (contentRow.element === 'button') {
                        return (
                            <div className={className} style={{
                                marginBottom: contentRow.marginBottom,
                                marginTop: contentRow.marginTop,
                                width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}>
                                {button()}
                            </div>
                        )
                    }

                    if (contentRow.element === 'input') {
                        return (
                            <div className={className} style={{
                                marginBottom: contentRow.marginBottom,
                                marginTop: contentRow.marginTop,
                                width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}>
                                {input()}
                            </div>
                        )
                    }

                    if (contentRow.element === 'textarea') {
                        return (
                            <div className={className} style={{
                                marginBottom: contentRow.marginBottom,
                                marginTop: contentRow.marginTop,
                                width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}>
                                {textarea()}
                            </div>
                        )
                    }

                    if (contentRow.element === 'selectOptions') {
                        return (
                            <div className={className} style={{
                                marginBottom: contentRow.marginBottom,
                                marginTop: contentRow.marginTop,
                                width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}>
                                {selectOptions()}
                            </div>
                        )
                    }

                    if (contentRow.element === 'counter') {
                        return (
                            <div className={className} style={{
                                marginBottom: contentRow.marginBottom,
                                marginTop: contentRow.marginTop,
                                width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}>
                                {countdown()}
                            </div>
                        )
                    }

                    const text = () => {
                        if (!contentRow.text) {
                            return
                        }

                        if (contentRow.renderHtml) {
                            return (
                                <p className={'render-html'} style={{
                                    color: contentRow.color,
                                }} dangerouslySetInnerHTML={{__html: __(contentRow.text, properties, userData)}}/>
                            )
                        }

                        return (
                            <p style={{
                                color: contentRow.color,
                            }}>{__(contentRow.text, properties, userData)}</p>
                        )
                    }

                    const line = () => {
                        if (typeof contentRow.line !== 'boolean' || contentRow.line === false) {
                            return
                        }

                        return (
                            <hr style={{
                                borderColor: contentRow.color ? contentRow.color : 'rgba(0, 0, 0, 0.87)',
                                borderWidth: 0,
                                borderTopWidth: 1,
                                display: 'none',
                            }}/>
                        )
                    }

                    return (
                        <div key={key} className={className} style={{
                            marginBottom: contentRow.marginBottom,
                            marginTop: contentRow.marginTop,
                            width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}>
                            {contentRow.image ? (<Image style={contentRow.imageStyle} marginTop={12}
                                                        height={100}>{contentRow.image}</Image>) : ''}
                            {text()}

                            {line()}

                            {subContent()}
                        </div>
                    )
                }) : ''}
            </div>
        )
    }

    const button = () => {
        let value: null | string = null;

        if (step.type === 'input') {
            value = inputValue;
        }

        let className = classes.button;
        if ("button" in step && typeof step.button === 'object' && "style" in step.button && step.button.style === 'primary') {
            className = classes.primaryButton
        }

        if ("button" in step && step.button) {
            if (step.button.image) {
                return (
                    <button style={"button" in step && step.button ? step.button.buttonStyle : {}} disabled={step.type === 'input' && inputValue === ''} className={classes.imageButtonWrapper}
                            onClick={() => setValue(value)}>
                        <Image height={'auto'} width={'100%'}>{step.button.image}</Image>
                    </button>
                );
            }

            if (step.button.text) {
                return (
                    <button style={"button" in step && step.button ? step.button.buttonStyle : {}} disabled={step.type === 'input' && inputValue === ''} className={className}
                            onClick={() => setValue(value)}>
                        {__(step.button.text, properties, userData)}
                    </button>
                )
            }
        }

        // @ts-ignore
        return (
            <button style={"button" in step && step.button ? step.button.buttonStyle : {}} disabled={step.type === 'input' && inputValue === ''} className={className}
                    onClick={() => setValue(value)}>
                Continue
            </button>
        );
    }

    const input = () => {
        return (
            <input type='text' placeholder={"placeholder" in step ? step.placeholder : ''}
                   className={classes.input + ' normalInput'} onChange={(event) => {
                setInputValue(event.target.value);
            }}/>
        );
    }

    const textarea = () => {
        return (
            <textarea placeholder={"placeholder" in step ? step.placeholder : ''}
                      className={classes.input + ' normalTextarea'} onChange={(event) => {
                setInputValue(event.target.value);
            }} style={{
                height: 125,
                outline: 'none',
            }}/>
        );
    }

    const closeIcon = () => {
        if (step.skipable === false) {
            return (
                <div/>
            )
        }

        return (
            <p style={{
                color: '#707070',
                fontSize: 22,
                background: 'white',
                display: 'inline-block',
                width: 32,
                height: 32,
                textAlign: 'center',
                borderRadius: '100%',
                marginLeft: 15,
            }} onClick={close}>x</p>
        );
    }

    const processBar = () => {
        if (step.hideProcess === true) {
            return (
                <div/>
            )
        }

        const bars: boolean[] = [];
        for (let i = 0; i < stepAmount; i++) {
            bars.push(i <= stepNo);
        }

        return (
            <div className={'bar-wrapper'} style={{
                padding: '0 15px',
                width: 'calc(100% - 20px)',
                marginTop: -15,
            }}>
                {bars.map((status, barKey) => {
                    return (
                        <div style={{
                            display: 'inline-block',
                            width: 'calc(' + (100 / bars.length) + '% - 4px)',
                            backgroundColor: status ? '#707070' : '#FFFFFF',
                            margin: '0 2px',
                            height: 4,
                            borderRadius: 5,
                        }} className={'single-bar'} key={barKey}/>
                    )
                })}
            </div>
        );
    }

    const selectOptions = () => {
        return (
            <div>
                {"options" in step && typeof step.options !== 'undefined' ? step.options.map((option, key) => {
                    return (
                        <button onClick={() => {
                            setValue(option.id)
                        }} className={classes.selectOption} value={option.id} key={key} style={{
                            color: (typeof option.color === 'string' ? option.color : undefined),
                            borderColor: (typeof option.color === 'string' ? option.color : undefined),
                            backgroundColor: (typeof option.background === 'string' ? option.background : undefined)
                        }}>
                            {__(option.text, properties, userData)}
                        </button>
                    );
                }) : ''}
            </div>
        )
    };

    const [counter, setCounter] = React.useState(step.type === 'countdown' ? step.seconds : -1);

    const countdown = () => {
        if ("hideCounter" in step && step.hideCounter) {
            return (
                <div/>
            )
        }

        return (
            <div className={classes.countdown}>
                0:{counter.toString().length === 2 ? counter : '0' + counter}
            </div>
        )
    };

    useEffect(() => {
        if (!isCurrent) {
            return;
        }

        if (step.type === 'waves') {
            setTimeout(() => {
                setValue(null)
            }, step.time);
        }

        if (step.type === 'countdown') {
            runCounter();
        }
    }, [isCurrent]);

    useEffect(() => {
        if (!isCurrent) {
            return;
        }

        if (counter > 0) {
            return runCounter();
        }

        if (counter === 0) {
            setValue(null)
        }
    }, [counter]);

    const runCounter = () => {
        setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
    };

    let stepAudioFile: string|null = null;
    if ('audio' in step) {
        stepAudioFile = '/' + step.audio;

        // @ts-ignore
        if ('device' in window && window.device.platform.toLowerCase() === 'android') {
            stepAudioFile = '/android_asset/www/' + step.audio;
        }

        // @ts-ignore
        else if ('device' in window && window.device.platform.toLowerCase() === 'ios') {
            stepAudioFile = '' + step.audio;
        }
    }

    const contentWrapper = () => {
        switch (step.type) {
            case "button":
                return (
                    <div className={classes.contentWrapper} style={{
                        backgroundImage: step.background ? AssetHelper.cssUrl(step.background) : undefined,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                        {closeIcon()}

                        {processBar()}

                        {content()}

                        {/*button()*/}

                        {isCurrent && stepAudioFile ? (<ReactAudioPlayer
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                            }}
                            src={stepAudioFile}
                            autoPlay={true}
                            controls={true}
                            // onError={(event) => {
                            //     alert(stepAudioFile);
                            //     alert('error! ' + JSON.stringify(event));
                            // }}
                        />) : ''}
                    </div>
                )

            case "input":
                return (
                    <div className={classes.contentWrapper} style={{
                        backgroundImage: step.background ? AssetHelper.cssUrl(step.background) : undefined,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                        {closeIcon()}

                        {processBar()}

                        {content()}

                        {/*input()*/}

                        {/*button()*/}

                        {isCurrent && stepAudioFile ? (<ReactAudioPlayer
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                            }}
                            src={stepAudioFile}
                            autoPlay={true}
                            controls={true}
                            // onError={(event) => {
                            //     alert('error! ' + JSON.stringify(event));
                            // }}
                        />) : ''}
                    </div>
                )

            case "selection":
                return (
                    <div className={classes.contentWrapper} style={{
                        backgroundImage: step.background ? AssetHelper.cssUrl(step.background) : undefined,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                        {closeIcon()}

                        {processBar()}

                        {content()}

                        {/*selectOptions()*/}

                        {isCurrent && stepAudioFile ? (<ReactAudioPlayer
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                            }}
                            src={stepAudioFile}
                            autoPlay={true}
                            controls={true}
                            // onError={(event) => {
                            //     alert('error! ' + JSON.stringify(event));
                            // }}
                        />) : ''}
                    </div>
                )

            case "waves":
                let audioFile = '/waves.mp3';

                // @ts-ignore
                if ('device' in window && window.device.platform.toLowerCase() === 'android') {
                    audioFile = '/android_asset/www/waves.mp3';
                }

                // @ts-ignore
                else if ('device' in window && window.device.platform.toLowerCase() === 'ios') {
                    audioFile = 'waves.mp3';
                }

                return (
                    <div className={classes.waves}>
                        {closeIcon()}

                        {processBar()}

                        <div style={{
                            zIndex: 9999999,
                            position: 'absolute',
                            left: 0,
                        }}>{content()}</div>

                        <div className={classes.wave1}/>
                        <div className={classes.wave2}/>
                        <div className={classes.wave3}/>
                        <div className={classes.whitesand}/>

                        {isCurrent ? (<ReactAudioPlayer
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                            }}
                            src={audioFile}
                            autoPlay={true}
                            controls={true}
                            // onError={(event) => {
                            //     alert('error! ' + JSON.stringify(event));
                            // }}
                        />) : ''}
                    </div>
                )

            case "countdown":
                return (
                    <div className={classes.contentWrapper} style={{
                        backgroundImage: step.background ? AssetHelper.cssUrl(step.background) : undefined,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                        {closeIcon()}

                        {processBar()}

                        {content()}

                        {/*countdown()*/}

                        {isCurrent && stepAudioFile ? (<ReactAudioPlayer
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                            }}
                            src={stepAudioFile}
                            autoPlay={true}
                            controls={true}
                            // onError={(event) => {
                            //     alert('error! ' + JSON.stringify(event));
                            // }}
                        />) : ''}
                    </div>
                )

            default:
                return (
                    <div className={classes.contentWrapper}>
                        ?????

                        {closeIcon()}

                        {processBar()}

                        {content()}

                        {isCurrent && stepAudioFile ? (<ReactAudioPlayer
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                            }}
                            src={stepAudioFile}
                            autoPlay={true}
                            controls={true}
                            // onError={(event) => {
                            //     alert('error! ' + JSON.stringify(event));
                            // }}
                        />) : ''}
                    </div>
                )
        }
    }

    const rootAttributes = {
        // onClose: close,
        // @ts-ignore
        open,
        className: classes.root,
        style: {
            zIndex: 99999999,
        },
    };

    return (
        <Drawer transitionDuration={0} anchor="right" {...rootAttributes}>
            {contentWrapper()}
        </Drawer>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseStep)
