import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface IInternetConnection {
    online: boolean
}

let currentOnlineState: boolean = true;
// Check the device has support for the navigator.onLine state.
if (typeof navigator === 'object' && typeof navigator.onLine !== 'undefined') {
    currentOnlineState = navigator.onLine;
}

const initialState: IInternetConnection = {
    online: currentOnlineState
};

const internetConnectionReducer: Reducer<IInternetConnection, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INTERNET_ONLINE:
            return {
                ...state,
                online: true,
            };
        case ActionTypes.INTERNET_OFFLINE:
            return {
                ...state,
                online: false,
            };
        default:
            return state
    }
};

export default internetConnectionReducer
