import React, {useEffect} from 'react'
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import {
    IRegisterDevice, ISetNotificationState,
    registerDevice as doRegisterDevice,
    setNotificationState as doSetNotificationState
} from '../Actions/notificationActions';
import {STATUSES} from "../Reducers/notificationState";
import {setUserData} from "../Actions/userDataActions";
import {IUserData} from "../Types/appTypes";

export interface IOwnProps {
    children?: any[] | any
}

export interface INotificationState {
    status: 'none' | 'failed' | 'registered'
    token: string | null
}

interface IStateProps {
    status: string | null
    userData: IUserData
}

interface IDispatchProps {
    registerDevice: (userData: IUserData, token: string) => IRegisterDevice,
    setNotificationState: (status: string, token: string|null) => ISetNotificationState,
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const Authenticated: React.FunctionComponent<Props> = (props) => {
    const {userData, registerDevice, status, setNotificationState} = props;

    const log = (message: string) => {
        console.log(message);
        // alert(message);
    };

    log('status: ' + status);
    log('uuid: ' + userData.user.uuid);
    log('token: ' + userData.user.token);

    const registrationFailed = (error: any) => {
        log('Failed to register for notifications.');
        log('Error: ' + error);

        // Save tried state so we don't spam asking
        setNotificationState(STATUSES.FAILED, null);
    };

    const registrationSucceed = (newToken: string) => {
        log('Succeed to register for notifications.');
        log('newToken: ' + newToken);

        // Save the token
        setNotificationState(STATUSES.REGISTERED, newToken);
        registerDevice(userData, newToken)
    };

    const canRegister = (): boolean => {
        // @ts-ignore
        return typeof FCMPlugin === 'object';
    };

    const shouldRegister = (): boolean => {
        return status !== STATUSES.FAILED;
    };

    const register = () => {
        if (!canRegister()) {
            log('Cannot register.');
            return;
        }

        if (!shouldRegister()) {
            log('Shouldn\'t register. Maybe it was already rejected.');
            return;
        }

        if (!registered()) {
            log('Not already registered. Registering...');

            getToken();
        }

        if (status === STATUSES.REGISTERED && userData.user.token) {
            registerDevice(userData, userData.user.token);
        }
    };

    const registered = (): boolean => {
        return status === STATUSES.REGISTERED && userData.user.token !== null && userData.user.token !== '';
    };

    const getToken = () => {
        // @ts-ignore
        FCMPlugin.getToken((newToken: any) => {
            registrationSucceed(newToken);

            // @ts-ignore
            FCMPlugin.subscribeToTopic('cordova');
        }, (error: any) => {
            registrationFailed(error);
        });
    };

    useEffect(() => {
        // Register for push notifications
        register();
    });

    return (
        <div>
            {props.children}
            <div style={{
                background: 'red',
                color: 'white',
                position: 'fixed',
                bottom: 50,
                left: 0,
                right: 0,
                width: '100%',
                padding: 10,
                fontSize: 10,
                display: 'none',
            }}>
                <p>Status: {status}</p>
                <p>uuid: {userData.user.uuid}</p>
                <p>token: {userData.user.token}</p>
            </div>
        </div>
    );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ notificationState, userData }) => ({
    status: notificationState.status,
    userData,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    registerDevice: (userData: IUserData, token: string) => {
        userData.user.token = token;
        setUserData(userData)

        dispatch(doSetNotificationState(STATUSES.REGISTERED, token));
        return dispatch(doRegisterDevice(token));
    },
    setNotificationState: (status: string|null, token: string|null): any => {
        if (status === null) {
            status = STATUSES.NOT_REGISTERED;
        }

        dispatch(doSetNotificationState(status, token));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
