import React from 'react';
import {MapStateToProps, MapDispatchToPropsFunction, connect} from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {IStoreState} from '../Reducers'
import 'date-fns';
import {IExercise, IProperties, IUserData} from "../Types/appTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import ExerciseStep from "./ExerciseStep";
import {setUserData} from "../Actions/userDataActions";
import Image from "./Image";
import peachgrey from '../assets/images/peachgrey.png'
import Winnerpeach from '../assets/images/Winnerpeach.png'
import Help from "./Help";

export interface IOwnProps {
    exercise: IExercise
    completed: boolean
    locked: boolean
    exerciseOpen: boolean
    setExerciseOpen: any
}

interface IStateProps {
    userData: IUserData,
    properties: IProperties
}

interface IDispatchProps {
    setUser: (data: IUserData) => any
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({userData, properties}) => ({
    userData,
    properties,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    setUser: (data) => {
        return dispatch(setUserData(data))
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: '#578B7E',
            marginTop: 30,
            padding: '3px 15px',
            position: 'relative',
            minHeight: 130,
            marginBottom: 30,
            textAlign: 'left',
            borderRadius: 20,
        },
        exerciseName: {
            fontSize: 18,
            color: 'white',
            marginBottom: 0,
            maxWidth: 150,
        },
        exerciseCategory: {
            fontSize: 18,
            color: 'white',
            marginTop: 3,
            maxWidth: 150,
            position: 'absolute',
            bottom: 0,
        },
        exerciseRewardName: {
            fontSize: 18,
            color: 'white',
            marginBottom: 0,
            textAlign: 'center',
            paddingBottom: 15,
        },
        exerciseRewardCategory: {
            fontSize: 18,
            color: 'white',
            marginTop: 3,
            textAlign: 'center',
            paddingTop: 15,
            fontWeight: 'bold',
        },
        completedIcon: {
            background: '#E092C7',
            border: '5px solid #7EF251',
            borderRadius: '100%',
            padding: 12,
        },
        checkIcon: {
            position: 'absolute',
            top: -10,
            right: -10,
            background: '#79EF8F',
            padding: 10,
            borderRadius: '100%',
        },
        lockedText: {
            // color: '#707070',
        },
    }),
);

const Exercise = (props: IProps) => {
    const {locked, completed, exercise, userData, setUser, properties} = props;
    const classes = useStyles();

    const openState = {};
    const setOpenState = {};

    let currentlyOpen = 0;

    const updateOpenStates = () => {
        exercise.steps.forEach((step, key) => {
            if (key <= currentlyOpen) {
                setOpenState[key](true);
            } else {
                setOpenState[key](false);
            }
        });

        setCurrent(currentlyOpen);
    };

    /*
    const helpModal = () => {
        if (exercise.help) {
            return <Help />
        }
    };
    */

    const open = () => {
        if (locked) {
            alert('To access this exercise, you have to finish the exercises in the previous chapter.');
            return
        }

        if (completed && exercise.reward !== true) {
            return
        }

        // @ts-ignore
        window.openExerciseHelp = (event: any) => {
            event.preventDefault();

            console.log('EXERCISE ' + exercise.id);

            setHelpOpen(true)

            return false;
        }

        // @ts-ignore
        window.currentExercise = exercise.id;

        currentlyOpen = 0;
        updateOpenStates()
    }

    const complete = () => {
        // @TODO: Save data

        close();

        // If intro, open first exercise
        if (exercise.intro) {
            // @ts-ignore
            document.querySelectorAll('.exercise:not(#intro)')[0].click();
        }
    };

    const previous = (id: number) => {
        currentlyOpen = id - 1;

        updateOpenStates();
    };

    // @ts-ignore
    window.goTo = (no: number) => {
        next(no - 1);
    };

    const next = (no: number) => {
        currentlyOpen = no + 1;

        // Close if reached end.
        if (currentlyOpen + 1 > Object.entries(openState).length) {
            return complete();
        }

        updateOpenStates();
    };

    const close = () => {
        currentlyOpen = -1;

        // @ts-ignore
        window.currentExercise = null;

        updateOpenStates();
    };

    const introData: Array<{ id: number, value: null | number | string }> = [];

    const saveValue = (id: number, value: string, finished: boolean) => {
        if (!userData.user.finishedIntro) {
            introData.push({
                id,
                value,
            });

            introData.forEach((data) => {
                properties.chapters.forEach((chapter) => {
                    chapter.exercises.forEach((checkExercise) => {
                        checkExercise.steps.forEach((step) => {
                            if (id === step.id && "key" in step && typeof step.key === 'string') {
                                userData.user[step.key] = value;
                            }
                        })
                    })
                })
            })

            if (finished) {
                userData.user.finishedIntro = true;
            }

            console.log('@userData', userData);
            setUser(userData)

            return
        }

        let saveOnExerciseId: number | null = null;
        // update if exists
        userData.exercises.forEach((journey, exerciseKey) => {
            if (journey.id === exercise.id) {
                saveOnExerciseId = exercise.id;

                userData.exercises[exerciseKey].finished = finished;

                let saveOnStepId: number | null = null;
                // update if exists
                userData.exercises[exerciseKey].steps.forEach((step, stepKey) => {
                    if (step.id === id) {
                        saveOnStepId = step.id;

                        userData.exercises[exerciseKey].steps[stepKey] = {
                            id,
                            value,
                        };
                    }
                });

                // add new row if it doesn't exists yet
                if (saveOnStepId === null) {
                    userData.exercises[exerciseKey].steps.push({
                        id,
                        value,
                    });
                }
            }
        })
        // add new row if it doesn't exists yet
        if (saveOnExerciseId === null) {
            userData.exercises.push({
                id: exercise.id,
                finished,
                steps: [
                    {
                        id,
                        value
                    },
                ],
            });
        }

        setUser(userData);
    };

    const [current, setCurrent] = React.useState(-1);

    const modal = () => {
        return (
            <div style={{zIndex: 9999999999,}}>
                {exercise.steps.map((step, key) => {
                    if (typeof openState[key] === "undefined") {
                        [openState[key], setOpenState[key]] = React.useState(false);
                    }

                    return (
                        <ExerciseStep stepAmount={exercise.steps.length} open={/*openState[key]*/key === current}
                                      saveValue={(value: string) => {
                                          saveValue(step.id, value, key + 1 === Object.entries(openState).length)
                                      }} isCurrent={key === current} key={key} stepNo={key} step={step}
                                      previous={previous}
                                      next={next} close={close}/>
                    )
                })}
            </div>
        )
    };

    const [helpOpen, setHelpOpen] = React.useState(false)

    return (
        <div>
            {modal()}

            {exercise.help ? (<Help help={exercise.help} open={helpOpen} setOpen={setHelpOpen}/>) : ''}

            <div className={classes.root} style={{
                backgroundColor: locked ? '#E4E4E4' : exercise.color,
                boxShadow: '5px 10px 10px #00000029',
            }}  onClick={() => open()}>
                <div id={(exercise.intro ? 'intro' : '')} className={'exercise'}>
                    {exercise.reward !== true ? (
                        <div>
                            {exercise.icon ? (<Image style={{
                                width: '100px',
                                position: 'absolute',
                                bottom: 0,
                                right: 20,
                                filter: locked ? 'grayscale(100%)' : undefined,
                            }}>{exercise.icon}</Image>) : ''}
                            {completed ? <FontAwesomeIcon icon={faCheck} color={'white'} size={"4x"}
                                                          className={classes.checkIcon}/> : ''}
                            <p className={classes.exerciseName + ' ' + (locked ? classes.lockedText : '')}>{exercise.name}</p>
                            <p className={classes.exerciseCategory + ' ' + (locked ? classes.lockedText : '')}>{exercise.category}</p>
                        </div>
                    ) : ''}

                    {exercise.reward === true ? (
                        <div style={{textAlign: 'center'}}>
                            <p className={classes.exerciseRewardCategory + ' ' + (locked ? classes.lockedText : '')}>{exercise.name}</p>

                            <Image style={{
                                width: '48px',
                            }}>{locked ? peachgrey : Winnerpeach}</Image>

                            {!locked ? (<p className={classes.exerciseRewardName + ' ' + (locked ? classes.lockedText : '')}>{exercise.category}</p>) : ''}
                        </div>
                    ) : ''}
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Exercise)
