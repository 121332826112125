import {ILocalization} from 'mobile/Types/appTypes'
import {Action, ActionTypes} from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

const defaultTranslations = {
    "da": {
        // login
        "signOn-header-label": "\n    .title = Velkommen! Log venligst ind.",
        "signOn-next-button": "Næste",
        "signOn-email-label": "\n    .label = Email adresse",

        // login/password
        "signOn-password-header-label": "\n    .title = Velkommen! Log venligst ind.",
        "signOn-password-next-button": "Næste",
        "signOn-password-password-label": "\n    .placeholder = Kodeord",
        "signOn-password-forgot-password-label": "Glemt kodeord?",

        // home
        "home-label": "Hjem",
        "home-menu-of-the-day": "\n    .title = Dagens menu\n    .rightOptionText = Vis hele ugen",
        "home-news": "\n    .title = Nyheder\n    .rightOptionText = Vis alle",
        "home-upcoming-meetings": "Kommende møder",
        "home-contact-the-canteen": "Kontakt kantinen",
        "home-no-planned-meetings": "Ingen planlagte møder",
        "home-plan-meeting": "Planlæg et møde",

        // navigation
        "navigation-home": "\n    .primary = Hjem",
        "navigation-helpCenter": "\n    .primary = Hjælpe Center",
        "navigation-myMeetings": "\n    .primary = Mine Møder",
        "navigation-moreSettings": "\n    .primary = Flere indstillinger",
        "navigation-logout": "Log ud",
        "navigation-balance": "Saldo",

        // Refill account
        "refill-current-balance": "Nuværende balance:",
        "refill-total-balance": "Din totale balance må ikke overstige 2.000 kr",
        "refill-step-description-1": "\n    .description = Indtast beløb",
        "refill-step-description-1-custom": "Indtast andet beløb:",
        "refill-step-description-1-custom-placeholder": "\n .label = Beløb",
        "refill-step-description-2": "\n.description = Betalingsform\n .helperText = Beløb må ikke overstige 2000kr",
        "refill-other": "Andet",
        "refill-terms": "Jeg accepterer handelsbetingelser",
        "refill-confirm-payment": "Gennemfør betaling",

        // additional settings
        "additionalSettings-title": "Flere indstillinger",
        "additionalSettingsGroup-customerService": "Kundeservice",
        "additionalSettingsGroup-terms": "Betingelser",
        "additionalSettingsGroup-socialMedia": "Sociale Medier",
        "HELP_CENTER": "Hjælpecenter",
        "HELP_CENTER_MOBILE_APP_FAQ": "Bruger guide",
        "PRIVACY_TERMS": "Privatlivspolitik",
        "COOKIES_TERMS": "Cookie betingelser",
        "EULA": "EULA",

        // room finder
        // "roomFinder-availableResourceGroup-1": "Ledige rum",
        // "roomFinder-availableResourceGroup-2": "Ledige AV udstyr",
        // "roomFinder-availableResourceGroup-4": "Ledige biler",

        // Misc
        "now-badge": "\n    .label = Nu"
    },
    "en": {
        "show-all": "Show all",
        
        // login
        "signOn-header-label": "\n    .title = Welcome! Please sign in.",
        "signOn-next-button": "Next",
        "signOn-email-label": "\n    .label = Email address",

        // login/password
        "signOn-password-header-label": "\n    .title = Welcome! Please sign in.",
        "signOn-password-next-button": "Next",
        "signOn-password-password-label": "\n    .placeholder = Password",
        "signOn-password-forgot-password-label": "Forgot password?",

        // home
        "home-label": "Home",
        "home-menu-of-the-day": "\n    .title = Menu of the day\n    .rightOptionText = Show all week",
        "home-news": "\n    .title = News\n    .rightOptionText = {show-all}",
        "home-upcoming-meetings": "Upcoming meetings",
        "home-contact-the-canteen": "Contact the canteen",
        "home-no-planned-meetings": "You don't have any planned meetings",
        "home-plan-meeting": "Plan a meeting",

        // navigation
        "navigation-home": "\n    .primary = Home",
        "navigation-helpCenter": "\n    .primary = Help Center",
        "navigation-myMeetings": "\n    .primary = My Meetings",
        "navigation-moreSettings": "\n    .primary = More settings",
        "navigation-logout": "Logout",
        "navigation-balance": "Balance",

        "meeting-open-in-calendar": "Open in calendar",

        // additional settings
        "additionalSettings-title": "More settings",
        "additionalSettingsGroup-customerService": "Customer service",
        "additionalSettingsGroup-terms": "Terms",
        "additionalSettingsGroup-socialMedia": "Social media",
        "HELP_CENTER": "Help center",
        "HELP_CENTER_MOBILE_APP_FAQ": "User guide",
        "PRIVACY_TERMS": "Privacy policy",
        "COOKIES_TERMS": "Cookie terms",
        "EULA": "EULA",

        // room finder
        // "roomFinder-availableResourceGroup-1": "Available Rooms",
        // "roomFinder-availableResourceGroup-2": "Available AV Equipments",
        // "roomFinder-availableResourceGroup-4": "Available Cars",

        // Misc
        "now-badge": "\n    .label = Now"
    },
};

function getUserLocale(): string
{
    const userLocales = navigator.languages;
    const supportedLocales = Object.keys(defaultTranslations);

    for(const userLocale in userLocales) {
        if (userLocales[userLocale]) {
            for (const supportedLocale in supportedLocales) {
                if (supportedLocales[supportedLocale] === userLocales[userLocale]) {
                    return userLocales[userLocale];
                }
            }
        }
    }

    return 'en';
}

const defaultUserLocale: string = getUserLocale();

export const initialState: ILocalization = {
    locale: defaultUserLocale,
    translations: defaultTranslations,
};

const propertyReducer: Reducer<ILocalization, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        case ActionTypes.SET_TRANSLATIONS:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.locale]: action.translations
                },
            };
        default:
            return state
    }
};

export default propertyReducer
