import { createHashHistory } from 'history'
import { Store, applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer, { IStoreState } from './Reducers'
import { rootSaga } from './Sagas/sagas'

export const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorInfo) => console.log(error, errorInfo)
});

export function configureStore(preloadedState?: undefined): Store<IStoreState> {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeWithDevTools(               // enables Redux DevTools browser extension
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                sagaMiddleware,
                // ... other middleware ...
            ),
        ),
    );

    // Start running sagas
    sagaMiddleware.run(rootSaga);

    return store
}
