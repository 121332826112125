import { ActionTypes } from './actionConstants'

export interface IOpenSnackbar {
    readonly type: ActionTypes.OPEN_SNACKBAR
    readonly message: string
}

export interface ICloseSnackbar {
    readonly type: ActionTypes.CLOSE_SNACKBAR,
}

export const openSnackbar = (message: string): IOpenSnackbar => {
    return {
        type: ActionTypes.OPEN_SNACKBAR,
        message,
    };
};

export const closeSnackbar = (): ICloseSnackbar => ({
    type: ActionTypes.CLOSE_SNACKBAR
});
