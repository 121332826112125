import { ApiResponse } from '../Types/responseTypes'
import {http, getHeaders} from './http';
import ApiHelper from "../Helpers/ApiHelper";

export const registerDevice = (uuid: string, deviceToken: string/*, token: string*/): Promise<ApiResponse<void>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/notifications/token`), {
        method: 'post',
        headers: getHeaders({
            // token,
        }),
        body: JSON.stringify({
            "clientId": uuid,
            "deviceToken": deviceToken,
        }),
    }));
};
