import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {Roboto} from "shared/theme";

type IMaterialUIButtonProps = React.ComponentProps<typeof Button>

interface IProps {
    children?: any[] | any
    color?: "default" | "inherit" | "primary" | "secondary" | undefined
    disabled?: boolean
    display?: string
    href?: string
    marginBottom?: number | string
    marginLeft?: number | string
    marginRight?: number | string
    marginTop?: number | string
    onClick?: any
    target?: string
    textColor?: string
    type?: any
    variant?: "text" | "outlined" | "contained" | undefined
    wide?: boolean
    width?: string | number
    fontSize?: number | string
    floating?: 'bottom',
    endIcon?: IMaterialUIButtonProps['endIcon']
}


export default function(props: IProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: props.display,
                borderRadius: 19.5,
                fontFamily: Roboto,
                fontSize: props.fontSize ? props.fontSize : 14,
                letterSpacing: -0.25,
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                marginTop: props.marginTop,
                textTransform: 'none',
                width: props.width ? props.width : (props.wide ? (props.floating !== undefined ? 'calc(100% - 30px)' : '100%') : undefined),
                fontWeight: 400,
                lineHeight: '24px',
                padding: props.floating !== undefined ? '10px 20px' : undefined,
                position: props.floating !== undefined ? 'fixed' : undefined,
                bottom: props.floating === 'bottom' ? 20 : undefined,
            },
            span: {
                color: (props.textColor ? props.textColor : (props.variant === 'outlined' ? 'rgb(0, 144, 255)' : 'white')) + ' !important',
            },
        }),
    );

    const classes = useStyles();

    const rootAttributes = {
        className: classes.root,
        color: props.color ?? 'primary',
        disabled: props.disabled,
        href: props.href,
        onClick: props.onClick,
        target: props.target,
        type: props.type,
        endIcon: props.endIcon,
        variant: props.variant ?? 'contained',
    };

    return (
        <Button {...rootAttributes}>
            <span className={classes.span}>{props.children}</span>
        </Button>
    )
}

