import React, {useEffect} from 'react';
import {MapStateToProps, MapDispatchToPropsFunction, connect} from "react-redux";
import {createStyles, Drawer, makeStyles, Theme} from "@material-ui/core";
import {IStoreState} from '../Reducers'
import 'date-fns';
import AssetHelper from "../Helpers/AssetHelper";
import Image from './Image';
import mountainsNoCloud from '../assets/images/mountainsNoCloud.png'
import Peachbeingpeach from '../assets/images/Peachbeingpeach.png'
import LogoText2 from '../assets/images/LogoText2.png'

export interface IOwnProps {
    children: any | any[]
}

interface IStateProps {
    //
}

interface IDispatchProps {
    //
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({}) => ({
    //
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (/* dispatch */) => ({
    //
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'white',
            width: '100vw',
            height: '100vh',
            display: 'block',
            padding: 12,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            lineHeight: 1.4,
            overflowY: 'auto',
        },
        loading: {
            backgroundImage: AssetHelper.cssUrl(mountainsNoCloud),
            width: '100vw',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },
        icon: {
            width: 200,
            maxWidth: '30%',
            display: 'block',
            margin: '0 auto',
            marginTop: 'calc(50vh - 40vw)',
            background: 'white',
            borderRadius: '100%',
            marginBottom: 20,
        },
        logo: {
            width: 400,
            maxWidth: '50%',
            display: 'block',
            margin: '0 auto',
        },
    }),
);

const Loading = (props: IProps) => {
    const {children} = props;
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        setTimeout(() => {
            setOpen(false)
        }, 2000);
    });

    const rootAttributes = {
        // onClose: close,
        // @ts-ignore
        open,
        className: classes.root,
        style: {
            zIndex: 999999999,
        },
    };

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <div className={classes.loading}>
                    <Image className={classes.icon}>{Peachbeingpeach}</Image>
                    <Image className={classes.logo}>{LogoText2}</Image>
                </div>
            </Drawer>

            <div>
                {children}
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading)
