import React, {Component} from 'react';
import {push} from "connected-react-router";
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";

export interface IOwnProps {}

interface IStateProps {
  //
}

interface IDispatchProps {
    goToHome: () => void
}

// @ts-ignore
type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

class ErrorHandler extends Component {
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public props: any;
  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
        hasError: false
    };
  }

    public useEffect() {
        this.state.hasError = false;
    }

  public componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
          <div>
            <h1 style={{
              width: '100%',
              textAlign: 'center',
            }}>Something went wrong.</h1>

            <p onClick={() => {
                this.state.hasError = false;
              this.props.goToHome();
            }} style={{
              width: '100%',
              textAlign: 'center',
              cursor: 'pointer',
            }}>Click here to go to front page</p>
          </div>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = () => ({
    //
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    goToHome: () => {
      dispatch(push('/'));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler)