import React from 'react'
import { createMuiTheme, ThemeProvider as Theme, Theme as MuiTheme } from "@material-ui/core";

// Colors
export const primaryColor = '#0090FF';
export const dangerColor = '#DB2741';
export const greyColor = '#969696';

// Theme Creator
export const colorTheme = (primary: string, secondary?: string): MuiTheme => {
    return createMuiTheme({
        palette: {
            primary: {
                main: primary,
            },
            secondary: {
                main: secondary ?? primary,
            },
        },
    })
};

// Themes
export const primaryTheme = colorTheme(primaryColor);
export const dangerTheme = colorTheme(dangerColor);

// Font Names
export const Roboto = "Roboto, 'Roboto-Regular', 'Roboto-Regular.ttf', sans-serif !important";

// Props
export interface IProps {
    children: any | any[]
    theme?: any
}

// Providers
export const ThemeProvider = (props: IProps) => {
    return (
        <Theme theme={props.theme ?? primaryTheme}>
            {props.children}
        </Theme>
    )
};
export const DangerThemeProvider = (props: IProps) => {
    return (
        <Theme theme={dangerTheme}>
            {props.children}
        </Theme>
    )
};
export default ThemeProvider
