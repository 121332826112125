import React from 'react'
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {IStoreState} from "../Reducers";
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import Snackbar from "./Snackbar";
import InternetConnection from "./InternetConnection";
import HelpModal from "./HelpModal";
import ErrorDialog from "./ErrorDialog";
import { Route, Switch } from 'react-router-dom';
import ErrorHandler from './ErrorHandler';
import CompletedIntro from './CompletedIntro';
import MyOverview from "../Screens/MyOverview";
import Home from "../Screens/Home";
import Guest from "./Guest";
import Step6 from "../Screens/Intro/Step6";
import Step5 from "../Screens/Intro/Step5";
import Step4 from "../Screens/Intro/Step4";
import Step3 from "../Screens/Intro/Step3";
import Step2 from "../Screens/Intro/Step2";
import Step1 from "../Screens/Intro/Step1";
import Intro from "../Screens/Intro";
import Logout from 'mobile/Screens/Auth/Logout';

export interface IOwnProps {
    //
}

interface IDispatchProps {
    //
}

interface IStateProps {
    location: any
}

type Props = IOwnProps & IStateProps & IDispatchProps

const reset = () => {
    // @ts-ignore
    setLocaleStorageItem('$_user', JSON.stringify(defaultUser));

    // @ts-ignore
    setLocaleStorageItem('$_exercises', JSON.stringify(defaultExercises));
}

const App = (props: Props) => {
    return (
        <div className="App">
            <TransitionGroup className={'routes'}>
                <CSSTransition
                    key={props.location.key}
                    classNames="route"
                    timeout={300}
                >
                    <Switch location={props.location}>
                        {/* Auth */}
                        <Route exact={true} path="/logout">
                            <CompletedIntro>
                                <ErrorHandler>
                                    <Logout/>
                                </ErrorHandler>
                            </CompletedIntro>
                        </Route>

                        {/* Intro */}
                        <Route exact={true} path="/intro">
                            <Guest>
                                <ErrorHandler>
                                    <Intro/>
                                </ErrorHandler>
                            </Guest>
                        </Route>
                        <Route exact={true} path="/intro/1">
                            <Guest>
                                <ErrorHandler>
                                    <Step1/>
                                </ErrorHandler>
                            </Guest>
                        </Route>
                        <Route exact={true} path="/intro/2">
                            <Guest>
                                <ErrorHandler>
                                    <Step2/>
                                </ErrorHandler>
                            </Guest>
                        </Route>
                        <Route exact={true} path="/intro/3">
                            <Guest>
                                <ErrorHandler>
                                    <Step3/>
                                </ErrorHandler>
                            </Guest>
                        </Route>
                        <Route exact={true} path="/intro/4">
                            <Guest>
                                <ErrorHandler>
                                    <Step4/>
                                </ErrorHandler>
                            </Guest>
                        </Route>
                        <Route exact={true} path="/intro/5">
                            <Guest>
                                <ErrorHandler>
                                    <Step5/>
                                </ErrorHandler>
                            </Guest>
                        </Route>
                        <Route exact={true} path="/intro/6">
                            <Guest>
                                <ErrorHandler>
                                    <Step6/>
                                </ErrorHandler>
                            </Guest>
                        </Route>

                        {/* Journey */}
                        <Route exact={true} path="/">
                            <CompletedIntro>
                                <ErrorHandler>
                                    <Home/>
                                </ErrorHandler>
                            </CompletedIntro>
                        </Route>

                        {/* My Overview */}
                        <Route exact={true} path="/my-overview">
                            <CompletedIntro>
                                <ErrorHandler>
                                    <MyOverview/>
                                </ErrorHandler>
                            </CompletedIntro>
                        </Route>

                        <Route exact={true} path="/logout">
                            <ErrorHandler>

                                <button onClick={reset} style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    display: 'none',
                                }}>Reset my data
                                </button>

                            </ErrorHandler>
                        </Route>

                        <Route render={() => (<div>404</div>)}/>
                    </Switch>
                </CSSTransition>
            </TransitionGroup>

            <HelpModal/>
            <InternetConnection/>
            <ErrorDialog/>
            <Snackbar/>
        </div>
    )
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ router }) => ({
    location: router.location
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (/*dispatch*/) => ({
    //
});

export default connect(mapStateToProps, mapDispatchToProps)(App)
