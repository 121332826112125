export const Url = (url: string) => {
    return `.${url}`
};

export const CssUrl = (url: string) => {
    if (url.substring(0, 5) === 'data:') {
        return `url(${url})`
    }

    return `url(.${url})`
};

export default {
    cssUrl: CssUrl,
    url: Url
}
