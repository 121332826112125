import React, {Component} from 'react';

export interface IProps {
  close: () => void
}

class ErrorHandlerModal extends Component<IProps, { hasError: boolean }> {
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
        hasError: false
    };
  }

  public useEffect() {
    this.setState({ hasError: false })
  }

  public componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
          <div style={{width:'100vw',height:'100%',background:'white'}}>
            <h1 style={{
              width: '100%',
              textAlign: 'center',
            }}>Something went wrong.</h1>

            <p onClick={() => {
              this.setState({ hasError: false })
              this.props.close()
            }} style={{
              width: '100%',
              textAlign: 'center',
              cursor: 'pointer',
            }}>Click here to close modal</p>
          </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorHandlerModal
