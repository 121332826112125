import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

interface IProps {
    bottom?: number
    borderRadius?: number
    boxShadow?: string
    children?: any[] | any
    left?: any
    marginTop?: number
    marginLeft?: number
    marginRight?: number
    marginBottom?: number
    semiTransparent?: boolean
    verticalAlign?: boolean
    width?: any
    position?: 'absolute' | 'relative'
    height?: any
    minHeight?: any
    display?: 'block' | 'table',
    onClick?: () => any
    ContainerProps?: IContainerProps
}

export interface IContainerProps {
    readonly marginTop?: number
    readonly marginBottom?: number
}

export default function(props: IProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                backgroundColor: props.semiTransparent ? 'rgba(255,255,255,0.58)' : undefined,
                bottom: props.bottom,
                borderRadius: props.borderRadius !== undefined ? props.borderRadius : 6,
                boxShadow: props.boxShadow ?? '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                left: props.left ? props.left : (props.verticalAlign ? 0 : undefined),
                marginLeft: props.marginLeft ? props.marginLeft : (props.verticalAlign ? 15 : undefined),
                marginRight: props.marginRight ? props.marginRight : (props.verticalAlign ? 15 : undefined),
                marginTop: props.marginTop ?? undefined,
                marginBottom: props.marginBottom ?? undefined,
                position: props.position ? props.position : (props.verticalAlign ? 'absolute' : undefined),
                top: props.verticalAlign ? '50%' : undefined,
                transform: props.verticalAlign ? 'translate(0, -50%)' : undefined,
                width: props.width ? props.width : (props.verticalAlign ? 'calc(100% - 30px)' : undefined),
                height: props.height,
                minHeight: props.minHeight,
                display: props.display,
            }
        }),
    );

    const classes = useStyles();

    const ContainerProps: IContainerProps = props.ContainerProps ?? {};

    return (
        <div style={{
            marginTop: ContainerProps.marginTop ?? 2,
            marginBottom: ContainerProps.marginBottom ?? 3,
            width: '100%',
        }} onClick={props.onClick}>
            <Card className={classes.root}>
                {props.children}
            </Card>
        </div>
    )
}
