import React, {useEffect} from 'react'
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import {push} from "connected-react-router";

export interface IOwnProps {
    //
}

interface IStateProps {
    //
}

interface IDispatchProps {
    goTo: (step: number) => any
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const Intro = (props: Props) => {
    const {goTo} = props;

    useEffect(() => {
        goTo(1); // @TODO: Get step dynamically
        return;
    });

    return (
        <div />
    );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = () => ({
    //
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    goTo: (step: number) => dispatch(push('/intro/' + step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intro)
