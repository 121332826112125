import React, {useEffect} from 'react'
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import {LocalizationProvider as Localization, ReactLocalization} from "@fluent/react";
import { FluentBundle, FluentResource } from '@fluent/bundle'
import {getTranslations, IGetTranslations} from "../Actions/localizationActions";

export interface IOwnProps {
    children?: any
}

interface IStateProps {
    locale: string
    translations: any
}

interface IDispatchProps {
    loadTranslations: (locale: string) => IGetTranslations
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

function* generateBundles(userLocale: string, translations: any): Iterable<FluentBundle> {
    let messages: object = translations.en;
    if (typeof translations[userLocale] === 'object') {
        messages = translations[userLocale];
    }

    let ftl = "";
    for (const [key, value] of Object.entries(messages)) {
        ftl += `${key} = ${value}\n\n`
    }

    const locale = new FluentResource(ftl);
    const bundle = new FluentBundle(userLocale);

    bundle.addResource(locale);

    yield bundle
}

const LocalizationProvider: React.FunctionComponent<Props> = (props) => {
    const {loadTranslations, locale, translations} = props;
    const bundles = generateBundles(locale, translations);
    const localization = new ReactLocalization(bundles)

    useEffect(() => {
        loadTranslations(locale);
    }, [locale]);

    return (
        <Localization l10n={localization}>
            {props.children}
        </Localization>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ localization }) => ({
    locale: localization.locale,
    translations: localization.translations,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    loadTranslations: (locale: string) => {
        return dispatch(getTranslations(locale))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationProvider)
