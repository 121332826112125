import {IProperties} from 'mobile/Types/appTypes'
import {ActionTypes, Action} from 'mobile/Actions/actionConstants'
import {Reducer} from 'redux'
import picnic from '../assets/images/picnic.png'
import woods from '../assets/images/woods.png'
import Peachbeingpeach from '../assets/images/Peachbeingpeach.png'
import readingpeach from '../assets/images/readingpeach.png'
import river from '../assets/images/river.png'
import wavesSky from '../assets/images/wavesSky.png'
import riverWalk from '../assets/images/riverWalk.png'
import mountainsNoCloud from '../assets/images/mountainsNoCloud.png'
import Sky from '../assets/images/Sky.png'
import Unlock from '../assets/images/Unlock.png'
import Flowerfield from '../assets/images/Flowerfield.png'
import contemplatingpeach from '../assets/images/contemplatingpeach.png'
import hugging from '../assets/images/hugging.png'
import breathingpeach from '../assets/images/breathingpeach.png'
import mirrorpeach from '../assets/images/mirrorpeach.png'
import Bath from '../assets/images/Bath.png'
import walking from '../assets/images/walking.png'
import Winnerpeach from '../assets/images/Winnerpeach.png'
import Pinkgarden from '../assets/images/Garden.png'
import beach3 from '../assets/images/Beach3.png'
import HourGlass from '../assets/images/Hour glass.png'
import beachPeach from '../assets/images/beachPeach.png'
import Woodsdense from '../assets/images/WoodsDense.png'
import beach2Sky from '../assets/images/beach2Sky.png'
import loweredDownRiver from '../assets/images/loweredDownRiver.png'
import loweredRiverPeach from '../assets/images/loweredRiverPeach.png'
import Beachpeach1 from '../assets/images/Beachpeach1.png'
import Waterfall from '../assets/images/Waterfall.png'
import Peachshower from '../assets/images/Peachshower.png'
import Peachwaterpath from '../assets/images/Peachwaterpath.png'

export const initialState: IProperties = {
    version: 'v0.2',
    availableLanguages: [
        'en',
    ],
    host: 'https://feelgood.ulties.co/api',
    refreshContentInMinutes: 1,
    chapters: [
        {
            id: 1,
            intro: true,
            category: 'INTRO',
            name: 'INTRO',
            exercises: [
                {
                    intro: true,
                    id: 1,
                    name: 'Welcome',
                    category: 'Intro',
                    steps: [
                        {
                            id: 2,
                            background: woods,
                            skipable: false,
                            hideProcess: true,
                            key: 'firstname',
                            type: 'input',
                            title: 'Test',
                            placeholder: 'First name',
                            button: {
                                align: 'center',
                                text: 'Get started',
                                style: 'primary',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'bottom',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Your journey with My True Value is private, and there is no login. Just write your first name and get started.',
                                            color: '#707070',
                                        },
                                        {
                                            element: 'input',
                                        },
                                        {
                                            element: 'button',
                                            marginBottom: 50,
                                        },
                                        {
                                            text: '* My True Value is not intended to diagnose, treat or cure any disorders. We urge you to take good care of yourself and consult your doctor if you have disorders or traumas.',
                                            size: 'small',
                                            color: 'white',
                                        },
                                        {
                                            text: '** Your privacy is important to us. You are anonymous, and your data is saved on your phone. It means only you have access to your data.',
                                            size: 'small',
                                            color: 'white',
                                        },
                                        {
                                            text: 'Read our <a href="https://mytruevalueapp.com/terms_&_conditions.html" target="_blank">Terms & Conditions</a> and <a href="https://mytruevalueapp.com/privacy_policy.html" target="_blank">Privacy Policy</a>',
                                            renderHtml: true,
                                            size: 'small',
                                            color: 'white',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 11,
                            background: woods,
                            skipable: false,
                            hideProcess: true,
                            type: 'button',
                            title: 'Hi you',
                            button: {
                                align: 'center',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: '<strong>Welcome [$firstname]!</strong><br />And congratulations.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Healthy self-worth is the greatest and strongest gift you can give yourself.',
                                                },
                                                {
                                                    text: 'This guide will be your daily, helpful companion. It is your personal guide that will give you concrete reflections and exercises.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                }
            ],
        },
        {
            id: 2,
            category: 'Introduction',
            name: 'Getting started',
            exercises: [
                {
                    id: 2,
                    name: '15 seconds of breathing',
                    category: 'Self-care',
                    color: '#578B7E',
                    icon: Peachbeingpeach,
                    steps: [
                        {
                            id: 12,
                            skipable: false,
                            hideProcess: true,
                            type: 'button',
                            background: river,
                            title: 'A calm moment',
                            button: {
                                text: 'Start',
                                // align: 'center',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Through your journey, you will experience deep self-reflection and loving self-care. First, you start with an easy self-care exercise. Hereafter you are introduced to your self-worth journey.',
                                                },
                                                {
                                                    line: true,
                                                },
                                                {
                                                    text: 'A calm moment with yourself is a great beginning.',
                                                    bold: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 13,
                            skipable: false,
                            hideProcess: true,
                            type: 'waves',
                            background: wavesSky,
                            time: 15000,
                            title: 'Breathe',
                            content: [
                                {
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            text: 'Breath deeply in<br />Breath slowly out',
                                            renderHtml: true,
                                            align: 'center',
                                            size: 'big',
                                            color: '#85B6CC',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 14,
                            skipable: false,
                            hideProcess: true,
                            type: 'button',
                            background: riverWalk,
                            title: 'Great job',
                            button: {
                                text: 'Show my journey',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Great job, [$firstname].',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You are on your way to a happier you.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    // tooltip: 'This is the first exercise.',
                },
                {
                    id: 3,
                    name: 'Understand self-worth',
                    category: 'Self-care',
                    color: '#85B7CD',
                    icon: readingpeach,
                    steps: [
                        {
                            id: 3,
                            type: 'selection',
                            background: mountainsNoCloud,
                            title: 'I know how it is to',
                            options: [
                                {
                                    id: 1,
                                    text: 'Be unsure of my own opinion',
                                },
                                {
                                    id: 2,
                                    text: 'Regret what I did or didn\'t do',
                                },
                                {
                                    id: 3,
                                    text: 'Feel I\'m not good enough',
                                },
                                {
                                    id: 4,
                                    text: 'Feel weak',
                                },
                                {
                                    id: 5,
                                    text: 'Have no say in my own life',
                                },
                                {
                                    id: 6,
                                    text: 'Feel I\'m not intelligent enough',
                                },
                                {
                                    id: 7,
                                    text: 'Feel others don\'t really know me',
                                },
                                {
                                    id: 8,
                                    text: 'Feel I don\'t know myself',
                                },
                                {
                                    id: 9,
                                    text: 'Worry too much',
                                },
                                {
                                    id: 10,
                                    text: 'Worry what other people think',
                                },
                                {
                                    id: 13,
                                    text: 'Be insecure',
                                },
                                {
                                    id: 14,
                                    text: 'Be shy',
                                },
                                {
                                    id: 15,
                                    text: 'Feel incompetent',
                                },
                                {
                                    id: 16,
                                    text: 'Be silent about what I need',
                                },
                                {
                                    id: 17,
                                    text: 'Feel I don\'t dare to say my opinion',
                                },
                                {
                                    id: 18,
                                    text: 'Want my life to be different',
                                },
                                {
                                    id: 19,
                                    text: 'Find it difficult to make a decision',
                                },
                            ],
                            content: [
                                {
                                    styleSubContent: false,
                                    spaceX: true,
                                    content: [
                                        {
                                            text: 'Low self-worth brings a lot of negative feelings that keep you from being who you truly are.',
                                        },
                                        {
                                            text: 'Select one feeling you recognize.',
                                        },
                                        {
                                            text: 'I know how it is to:',
                                            bold: true,
                                        },
                                        {
                                            element: 'selectOptions',
                                        },
                                        {
                                            marginBottom: 50,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 4,
                            type: 'button',
                            background: mountainsNoCloud,
                            title: 'These negative feelings are common',
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'These negative feelings are common, [$firstname].',
                                                },
                                                {
                                                    text: 'Some feel that they are useful. In fact, many people worry if working on their self-worth makes them self-centered. But it is low self-worth that causes selfishness. People only need to highlight themselves or disregard others\' needs if they themselves do not feel balanced.',
                                                },
                                                {
                                                    text: 'When you know you have value, you have tolerance and respect for yourself. With that comes natural tolerance and respect for others.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 5,
                            type: 'button',
                            background: mountainsNoCloud,
                            title: 'Countless people struggle with low self-worth.',
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Countless people struggle with low self-worth. Including highly successful people.',
                                                    bold: true,
                                                    // size: 'semibig',
                                                },
                                                {
                                                    text: 'We are often motivated to do better and achieve success simply because we feel we are not good enough. The problem is that we can never really enjoy our success. We feel we don\'t deserve it. That someone one day will discover that we are really not that competent.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 6,
                            type: 'button',
                            background: mountainsNoCloud,
                            title: 'Your worth is inside of you.',
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'We are wrong when we feel we have to be or do something special to have worth.',
                                                },
                                                {
                                                    text: 'Your worth is inside of you.',
                                                    bold: true,
                                                    // size: 'semibig',
                                                },
                                                {
                                                    text: 'When you know your worth, you automatically become confident and open. You can fully enjoy every moment in life.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 7,
                            type: 'selection',
                            background: mountainsNoCloud,
                            title: 'I would love to',
                            options: [
                                {
                                    id: 20,
                                    text: 'Believe in me',
                                },
                                {
                                    id: 21,
                                    text: 'Understand myself',
                                },
                                {
                                    id: 22,
                                    text: 'Feel loved',
                                },
                                {
                                    id: 23,
                                    text: 'Reach my goals',
                                },
                                {
                                    id: 24,
                                    text: 'Feel happy',
                                },
                                {
                                    id: 25,
                                    text: 'Have strength',
                                },
                                {
                                    id: 26,
                                    text: 'Dare to live my life',
                                },
                                {
                                    id: 27,
                                    text: 'Feel present',
                                },
                                {
                                    id: 28,
                                    text: 'Dare to say how I feel',
                                },
                                {
                                    id: 29,
                                    text: 'Know how I feel',
                                },
                                {
                                    id: 30,
                                    text: 'Feel free',
                                },
                                {
                                    id: 31,
                                    text: 'Feel safe',
                                },
                                {
                                    id: 32,
                                    text: 'Feel proud of myself',
                                },
                                {
                                    id: 33,
                                    text: 'Know what I want',
                                },
                                {
                                    id: 34,
                                    text: 'Live the life I want',
                                },
                            ],
                            content: [
                                {
                                    styleSubContent: false,
                                    spaceX: true,
                                    content: [
                                        {
                                            text: 'Now consider the below. How would you like to feel right now? Choose one feeling.',
                                        },
                                        {
                                            text: 'This is just a reflection for you. Your choice does not affect your self-worth journey.',
                                        },
                                        {
                                            text: 'I would love to:',
                                            bold: true,
                                        },
                                        {
                                            element: 'selectOptions',
                                        },
                                        {
                                            marginBottom: 50,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 8,
                            type: 'button',
                            background: picnic,
                            title: 'This journey is for you.',
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'This journey is for you.',
                                                },
                                                {
                                                    text: 'Not for your work, your partner, your friends, or anybody else.',
                                                },
                                                {
                                                    text: 'Just for you!',
                                                },
                                                {
                                                    text: 'Take a deep breath.<br />Say this to yourself inside your head:',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: '<br />I will do this for myself.<br />I will [$7.lowercase]<br />I will succeed.',
                                                    align: 'center',
                                                    bold: true,
                                                    renderHtml: true,
                                                    size: 'normal',
                                                    italic: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 9,
                            type: 'button',
                            background: Sky,
                            title: 'Congrats! You have unlocked chapter 1',
                            button: {
                                text: 'Go to chapter 1',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    align: 'center',
                                                    image: Unlock,
                                                    imageStyle: {
                                                        background: 'white',
                                                        borderRadius: '100%',
                                                        padding: 20,
                                                        height: 'auto',
                                                        width: 115,
                                                        marginTop: -70,
                                                        marginBottom: -30,
                                                    },
                                                },
                                                {
                                                    text: 'Congrats, [$firstname]!<br />You have unlocked chapter 1',
                                                    bold: true,
                                                    renderHtml: true,
                                                    align: 'center',
                                                    marginTop: 50,
                                                },
                                                {
                                                    text: 'In chapter 1, you will examine the unconscious and negative beliefs you have about yourself.',
                                                },
                                                {
                                                    text: 'You will also practice paying attention to yourself and note what you really feel.<br />You must know what weighs you down to be able to fly.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Pat yourself on the shoulder. You can be proud of every step you take in this journey.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                    ],
                    // tooltip: 'This is the first exercise.',
                },
            ],
            help: {
                title: 'Introduction',
                slides: [
                    {
                        content: [
                            {
                                text: 'You have started your beautiful journey to a happier you.',
                            },
                            {
                                text: 'But before you can start to work on your self-worth, you must truly understand how extensively self-worth affects your life.',
                            },
                            {
                                text: 'This chapter is about why healthy self-worth is important to you and to your relationships.',
                            },
                            {
                                text: 'You will understand what opportunities it gives you and what limitations lie in having low self-worth.'
                            },
                        ],
                    },
                ],
            },
        },
        {
            id: 3,
            category: 'Chapter 1',
            name: 'Your beliefs about yourself',
            requiredChapterId: 2,
            requiredChapterText: 'Complete introduction to unlock',
            exercises: [
                {
                    id: 4,
                    name: 'Your Childhood love',
                    category: 'Reflection',
                    color: '#E7AAA3',
                    icon: contemplatingpeach,
                    steps: [
                        {
                            id: 10,
                            type: 'button',
                            title: 'Your worth is inside of you.',
                            background: Flowerfield,
                            button: {
                                text: 'Got it',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Your childhood love',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'Your self-worth is created in your early childhood.',
                                                },
                                                {
                                                    text: 'When you were born, you had one simple strategy: Someone had to love you, so they would take care of you (and keep you alive).',
                                                },
                                                {
                                                    text: 'So from a very early stage, you instinctively searched for love.',
                                                },
                                                {
                                                    text: 'That was clever!',
                                                },
                                            ],
                                        },
                                        {
                                            text: '<span style="max-width: 300px; display: block; margin: 0 auto;"><a href="#" onClick="window.openExerciseHelp(event)">Learn more</a> about the relationship between you and your parents</span>',
                                            align: 'center',
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 15,
                            type: 'button',
                            title: '@TODO',
                            background: Flowerfield,
                            button: {
                                text: 'Got it',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'If you had to be a certain way to get love, you would change to be that way.',
                                                },
                                                {
                                                    text: 'It has given you an idea of who you are and who you must be. It happens unconsciously but is still part of you in your evaluation of yourself as a grown-up.',
                                                },
                                                {
                                                    text: 'You will get 3 short questions to help you reflect.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 16,
                            type: 'selection',
                            title: 'If you had to be a certain way to get love, you would change to be that way.',
                            background: Flowerfield,
                            options: [
                                {
                                    id: 35,
                                    text: 'Yes, often',
                                },
                                {
                                    id: 36,
                                    text: 'Sometimes',
                                },
                                {
                                    id: 37,
                                    text: 'Not enough',
                                },
                                {
                                    id: 38,
                                    text: 'No, not at all',
                                },
                                {
                                    id: 39,
                                    text: 'I don\'t know',
                                },
                            ],
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            text: 'Think of yourself as a child',
                                        },
                                        {
                                            text: 'Did you feel understood by your parents or the ones who raised you?',
                                            bold: true,
                                        },
                                        {
                                            element: 'selectOptions',
                                        },
                                    ],
                                    paddingX: true,
                                }
                            ],
                        },
                        {
                            id: 17,
                            type: 'input',
                            title: 'When did your parents criticize you, get irritated by you, or get angry with you?',
                            background: Flowerfield,
                            placeholder: "Write here e.g.: \n\"my mom got irritated if...\" \n\"when I was angry/sad\" \n\"I remember one time...\" \n\"if I didn't do my homework\" \n\"I can't remember\"",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            text: 'Use the time you need to answer. But do not leave the exercise. If you can only think of a few things right now, type those in and move on. Write "I can\'t remember" if you cannot answer.',
                                            size: 'small',
                                            italic: true,
                                        },
                                        {
                                            text: 'Think of yourself as a child. When did your parents criticize you, get irritated by you, or get angry with you?',
                                            bold: true,
                                        },
                                        {
                                            element: 'textarea',
                                        },
                                        {
                                            text: 'Do you find it hard or sad to reflect on your parents? <span style="max-width: 300px; margin: 0 auto;"><a href="#" onClick="window.openExerciseHelp(event)">Read more</a></span>',
                                            align: 'center',
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                    paddingX: true,
                                }
                            ],
                        },
                        {
                            id: 18,
                            type: 'input',
                            title: 'When did you get compliments or encouragement from your parents (or from the ones who raised you)?',
                            background: Flowerfield,
                            placeholder: "Write here e.g.: \n\"when I was sweet\" \n\"when I was intelligent\" \n\"when I was helpful\" \n\"when I did my homework\" \n\"I can't remember\"",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            text: 'Do not worry about spelling mistakes or whether your answers make sense to anyone but you. This is your journey - it is for you only.',
                                            size: 'small',
                                            italic: true,
                                        },
                                        {
                                            text: 'When did you get compliments or encouragement from your parents (or from the ones who raised you)?',
                                            bold: true,
                                        },
                                        {
                                            element: 'textarea',
                                        },
                                        {
                                            text: 'Do you find it hard or sad to reflect on your parents? <span style="max-width: 300px; margin: 0 auto;"><a href="#" onClick="window.openExerciseHelp(event)">Read more</a></span>',
                                            align: 'center',
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                    paddingX: true,
                                },
                            ],
                        },
                        {
                            id: 19,
                            type: 'button',
                            title: '@TODO',
                            background: Pinkgarden,
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Well done, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You are doing great. <br>We are all evaluated from a very early stage. It is part of growing up. But we often judge ourselves (and others too) by the same evaluation we have experienced as children.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'A part of releasing you from your self-criticism is to understand why you judge yourself.',
                                                },
                                                {
                                                    text: 'Take good care of yourself this minute!',
                                                    bold: true,
                                                },
                                                {
                                                    text: '<span style="max-width: 300px; display: block; margin: 0 auto;">Did you <a href="#" onClick="window.openExerciseHelp(event)">read</a> about the relationship between you and your parents?</span>',
                                                    align: 'center',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                    ],
                    help: {
                        title: 'Bonus info',
                        slides: [
                            {
                                content: [
                                    {
                                        text: 'The relation between a child and her parents is the first love relationship that she experiences. That love experience is fundamental to her self-worth.',
                                    },
                                    {
                                        text: 'As children, we instinctively need to believe in our parents. We accept that we have to change a bit to get their love and understanding. When we do not feel understood or loved by our parents, we find a reason in ourselves.',
                                    },
                                    {
                                        text: 'For example, a child can conclude: "My dad gets irritated because I am hysterical. It is wrong of me to be hysterical". She accepts that she is not worthy of love and understanding when she is hysterical.',
                                    },
                                ],
                            },
                            {
                                content: [
                                    {
                                        text: 'Your personality and your parent\'s behavior create your conclusions of who you must be. When we realize we have to change a bit to get love, it always fosters grief. Grief because it is not everything about us that is good enough.',
                                    },
                                    {
                                        text: 'It all happens unconsciously. It is unconscious behavior from both you and your parents. Your parents did the best they could at the time.<br> Their self-worth has been strongly influenced by their own childhood and their own parents. They themselves have had to change to get love. What they have learned through their childhood will affect their parenting - and you.',
                                        renderHtml: true,
                                    },
                                ],
                            },
                        ],
                    }
                },
                {
                    id: 6,
                    name: 'Connect with yourself ',
                    category: 'Self-care',
                    color: '#DFC9A9',
                    icon: hugging,
                    steps: [
                        {
                            id: 20,
                            title: '@TODO 20',
                            type: 'button',
                            background: beach3,
                            button: {
                                text: 'Got it',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Connect with yourself.',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You cannot work with yourself if you are not able to be present with yourself.',
                                                },
                                                {
                                                    text: 'It is like in a love relation: You cannot have a close and loving love relationship with a partner that is never present with you and do not take the time to create a connection to you.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 21,
                            title: '@TODO 21',
                            type: 'button',
                            background: beach3,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Connection and presence are created when we get in contact with our body at this moment.',
                                                },
                                                {
                                                    text: 'It makes you become better at observing your thoughts, feelings, and actions.',
                                                },
                                                {
                                                    text: 'In this exercise, you will practice presence by hugging yourself.',
                                                },
                                                {
                                                    text: 'Does it sound a little awkward or silly?',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 22,
                            title: '@TODO 22',
                            type: 'button',
                            background: beach3,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Don\'t worry, [$firstname].',
                                                },
                                                {
                                                    text: 'Self-hugging is a strong tool.<br />When you hug yourself, your mind and body focus all their senses towards you.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'At the same time, a powerful mix of positive hormones is released in your body.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 23,
                            title: '@TODO 23',
                            type: 'button',
                            background: beach3,
                            button: {
                                text: 'Let\'s begin',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Now it is time to get out of your head.<br />It is time to practice actively.',
                                                    bold: true,
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You can hug yourself just as you please.<br />You can put arms below your chest or around your stomach. You can sit, stand or lie down. Don\'t worry if it does not feel right. The magic lies in your touch.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Psst: If other people are around, you can fold your arms in a non-obvious way, just gently squeezing yourself.',
                                                    italic: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 24,
                            title: '@TODO 24',
                            type: 'countdown',
                            background: beach3,
                            seconds: 20,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Put your arms around yourself now.',
                                            bold: true,
                                            align: 'center',
                                        },
                                        {
                                            image: HourGlass,
                                            align: 'center',
                                            imageStyle: {
                                                height: 200,
                                            }
                                        },
                                        {
                                            element: 'counter',
                                        },
                                        {
                                            text: 'Maybe you need a strong, intense hug or a soft and calming hug.',
                                            align: 'center',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 25,
                            title: '@TODO 25',
                            type: 'button',
                            background: beachPeach,
                            button: {
                                text: 'Well done',
                            },
                            audio: 'bling.mp3',
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Fantastic, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'When you hug yourself, your body responds chemically as if you were receiving a hug. You release oxytocin (“the love hormone”), serotonin ("the feel-good hormone"), and lower levels of cortisol ("the stress hormone").',
                                                },
                                                {
                                                    text: 'So, self-hugging is a great way to practice presence, but it is also a powerful tool to show yourself affection.',
                                                },
                                                {
                                                    text: 'You did great.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 7,
                    name: 'Feelings from your childhood',
                    category: 'Reflection',
                    color: '#578B7E',
                    icon: Peachbeingpeach,
                    steps: [
                        {
                            id: 26,
                            title: '@TODO 26',
                            type: 'button',
                            background: Woodsdense,
                            button: {
                                text: 'Got it',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Feelings from your childhood',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You will now continue your reflection on how you, as a child, concluded you should be.',
                                                },
                                                {
                                                    text: 'Like any other child, you have been through all kinds of feelings.',
                                                },
                                                {
                                                    text: 'Feelings that weren\'t embraced by the adults in your life are hard to embrace by yourself.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 27,
                            title: 'Was it OK for you to show your feelings?',
                            type: 'selection',
                            background: Woodsdense,
                            options: [
                                {
                                    id: 40,
                                    text: 'Yes',
                                },
                                {
                                    id: 41,
                                    text: 'Sometimes',
                                },
                                {
                                    id: 42,
                                    text: 'Mostly not',
                                },
                                {
                                    id: 43,
                                    text: 'No, not at all',
                                },
                                {
                                    id: 44,
                                    text: 'I don\'t know',
                                },
                            ],
                            content: [
                                {
                                    verticalAlign: 'top',
                                    styleSubContent: false,
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Think of yourself as a child.',
                                        },
                                        {
                                            text: 'Was it OK for you to show your feelings?',
                                            bold: true,
                                        },
                                        {
                                            element: 'selectOptions',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 28,
                            title: 'Was any of your feelings more welcome than others?',
                            type: 'input',
                            background: Woodsdense,
                            placeholder: "Write here: e.g. \n\"sadness was OK but not anger\", \n\"it was best if I did not have too much of any feeling\", \n\"If I was shy\" or \n\"I don't remember\" ...",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Use as much time as you need, but do not leave the exercise. If you cannot think of an answer write: "I can\'t remember".',
                                            italic: true,
                                            size: 'small',
                                        },
                                        {
                                            text: 'Was any of your feelings more welcome than others?',
                                            bold: true,
                                        },
                                        {
                                            element: 'textarea',
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 29,
                            title: 'How or when did you feel that your parents (or the ones that raised you) truly understood you?',
                            type: 'input',
                            background: Woodsdense,
                            placeholder: "Write here: e.g. \n\"I felt understood when I was sad\", \n\"I remember one time where ...\", or \n\"I don't remember\" ...",
                            content: [
                                {
                                    styleSubContent: false,
                                    paddingX: true,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            text: 'If English is not your primary language, you can answer in your own language. Do not worry about spelling mistakes or whether it sounds perfect. This is only for you.',
                                            italic: true,
                                            size: 'small',
                                        },
                                        {
                                            text: 'How or when did you feel that your parents (or the ones that raised you) truly understood you?',
                                            bold: true,
                                        },
                                        {
                                            element: 'textarea',
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 30,
                            title: '@TODO 30',
                            type: 'button',
                            background: picnic,
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Great job, [$firstname]',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'The reflection is part of changing your negative evaluation of yourself. Some feel that the questions can be difficult to answer, and others find it is easy. Whether it is difficult or easy for you, the most important part is that you have started reflecting. That slowly opens up to the possibility to look at yourself objectively.',
                                                },
                                                {
                                                    text: 'You can be proud! Proud that you are here … taking a moment of reflection!',
                                                    align: 'center',
                                                    bold: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 8,
                    name: 'Breathing',
                    category: 'self-care',
                    color: '#85B7CD',
                    icon: breathingpeach,
                    steps: [
                        {
                            id: 34,
                            title: '@TODO 34',
                            type: 'button',
                            background: wavesSky,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Breathing',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'Many people go through life in a zombie-like state. We seldom pay attention to ourselves or feel ourselves.',
                                                },
                                                {
                                                    text: 'But you can only accept and understand who you are if you take your time to listen, feel, and communicate with yourself.',
                                                },
                                                {
                                                    text: 'To do so, you have to be present with yourself.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 31,
                            title: '@TODO 31',
                            type: 'button',
                            background: wavesSky,
                            button: {
                                text: 'Let\'s go',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Presence goes beyond words and actions.',
                                                },
                                                {
                                                    text: 'It is your possibility to listen to and become aware of yourself.',
                                                },
                                                {
                                                    text: 'Breathing deeply is one of the best ways to get in contact with your body and be present with yourself.',
                                                },
                                                {
                                                    text: 'Now you will breathe deeply for 20 seconds.<br>A calm body leads to calm thoughts.',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            text: '<span style="max-width: 300px; display: block; margin: 0 auto; margin-bottom: 30px;">Why is it difficult to be present?<br /><a href="#" onClick="window.openExerciseHelp(event)">Learn more</a></span>',
                                            align: 'center',
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 32,
                            title: '@TODO 32',
                            type: 'waves',
                            time: 15000,
                            background: wavesSky,
                            skipable: false,
                            hideProcess: true,
                            content: [
                                {
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            text: 'Breathe in and out.<br />Fill your lungs with air.',
                                            renderHtml: true,
                                            size: 'normal',
                                            align: 'center',
                                            color: '#85B6CC',
                                        },
                                        {
                                            line: true,
                                            color: '#85B6CC',
                                        },
                                        {
                                            text: 'Sight it out slowly.',
                                            size: 'normal',
                                            align: 'center',
                                            color: '#85B6CC',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 33,
                            title: '@TODO 33',
                            type: 'button',
                            background: beach2Sky,
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Great job, [$firstname]',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'Breathing deeply impacts your body and mind. It improves your ability to focus, encourages positive thinking, and reduces symptoms of stress.',
                                                },
                                                {
                                                    text: 'Your journey has various breathing exercises as it is a powerful tool to practice presence with yourself.<br />Your body contains great power.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You are greater than you think!',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                                {
                                                    text: '<span style="max-width: 300px; margin: 0 auto;">Did you <a href="#" onClick="window.openExerciseHelp(event)">read</a> why it can be difficult to be present?</span>',
                                                    align: 'center',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    help: {
                        title: 'Bonus info',
                        slides: [
                            {
                                content: [
                                    {
                                        text: 'Our survival relies on learning from the past and on planning what we need to tackle in the future.',
                                    },
                                    {
                                        text: 'Regret, for example, is a mental mechanism that helps us avoid making the same mistake twice. Fear makes us alert to danger and prepares us to deal with what might happen. ',
                                    },
                                    {
                                        text: 'Awareness about the past and the future simply is part of our survival strategy as human beings. But it often gets too dominating, and we tend to forget the present moment. ',
                                    },
                                    {
                                        text: 'That is why many adults need to practice presence.',
                                    },
                                ],
                            }
                        ],
                    },
                },
                {
                    id: 9,
                    name: 'Your self-image',
                    category: 'Reflection',
                    color: '#E6A9A2',
                    icon: mirrorpeach,
                    steps: [
                        {
                            id: 35,
                            title: '@TODO 35',
                            type: 'button',
                            background: Flowerfield,
                            button: {
                                text: 'Got it',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Your Self-image',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'The way you are treated as a child, together with your personality, creates your inner and often unconscious images of who you are. ',
                                                },
                                                {
                                                    text: 'This happens to everybody, and often we carry those images with us far into adulthood.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 36,
                            title: '@TODO 36',
                            type: 'button',
                            background: Flowerfield,
                            button: {
                                text: 'Let\'s go',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'But we also listen to comments or descriptions of who we are. For example that we are stubborn, reasonable, intelligent, sensitive, or other things.<br />We hear that from our parents, in school, from grandparents, etc. ',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'This exercise will focus on your spoken stories: who have you been told you were?',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 37,
                            title: 'Which words were used to describe you when you were a child?',
                            type: 'input',
                            background: Flowerfield,
                            placeholder: "Write here: e.g. \n“Sweet”, \n“Stubborn”, \n“wild”, \n“brave”, \n\" I don't know\"",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Use as much time as you need, but do not leave the exercise. If you cannot think of an answer write: "I can\'t remember".',
                                            italic: true,
                                            size: 'small',
                                        },
                                        {
                                            text: 'Which words were used to describe you when you were a child?<br />For example, by your parents, grandparents, siblings, in school, etc.',
                                            bold: true,
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'textarea',
                                        },
                                        {
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 38,
                            title: 'Do you feel that what has been said about you was true?',
                            type: 'selection',
                            background: Flowerfield,
                            options: [
                                {
                                    id: 45,
                                    text: 'Yes, it was very true',
                                },
                                {
                                    id: 46,
                                    text: 'Most of it was true',
                                },
                                {
                                    id: 47,
                                    text: 'It was both true and wrong',
                                },
                                {
                                    id: 48,
                                    text: 'Most of it was wrong',
                                },
                                {
                                    id: 49,
                                    text: 'No, not at all',
                                },
                                {
                                    id: 50,
                                    text: 'I don\'t know',
                                },
                            ],
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Do you feel that what has been said about you was true?',
                                            bold: true,
                                        },
                                        {
                                            element: 'selectOptions',
                                        },
                                        {
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 39,
                            title: '@TODO 39',
                            type: 'button',
                            background: Peachwaterpath,
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'You are working great, [$firstname]!',
                                                },
                                                {
                                                    text: 'Sometimes we can say things like: I have always been stubborn.<br />We forget that we can also be the opposite. We contain much more than what has been said about us and what we think about ourselves.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You are good enough! And more important: you are more than your stories.',
                                                },
                                                {
                                                    text: 'You contain more than you give yourself credit for!',
                                                    bold: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 10,
                    name: 'Positive self-talk',
                    category: 'Self-care',
                    color: '#578B7E',
                    icon: Bath,
                    help: {
                        title: 'Bonus info',
                        slides: [
                            {
                                content: [
                                    {
                                        text: 'We all have two different voices inside of us: one that is caring, and one that is critical. Our caring voice brings self-compassion and encouragement, while the inner critical voice helps you recognize and remember where you have made a mistake, so you don\'t do it again.',
                                    },
                                    {
                                        text: 'But for most people, the inner critic is too dominating and brings scolding, shaming, nit-picking, and faultfinding. Instead of being a helper, your inner critic is degrading and punishing. It increases your feeling of self-hate without motivating you to change undesirable qualities or act in a constructive manner. It’s big and powerful, while the inner nurturer is small and ineffective.',
                                    },
                                ],
                            },
                            {
                                content: [
                                    {
                                        text: 'When you do positive self-talk you give your caring voice some power so your inner critic does not take it to the extreme.',
                                    },
                                    {
                                        text: 'You add positive thinking about yourself to your mindset to challenge the negative thoughts and make them less dominating.',
                                    },
                                    {
                                        text: 'The strength in positive self-talk lies in repetition. The more we say positive things about ourselves the more we can rewire our brain into more positive thinking about ourselves.',
                                    },
                                    {
                                        text: 'Together with reflection self-talk is a strong tool to increase your self-worth.',
                                    },
                                ],
                            },
                        ],
                    },
                    steps: [
                        {
                            id: 40,
                            title: '@TODO 40',
                            type: 'button',
                            background: mountainsNoCloud,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Positive self-talk',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'Sometimes when we start reflecting on our childhood, old emotions can appear. That is ok. It is important to embrace what you feel. ',
                                                },
                                                {
                                                    text: 'If you try to reject your feelings, you show yourself that what you feel is not important. But feelings do not disappear, even if we try to reject them. They stay inside of us. ',
                                                },
                                                {
                                                    text: 'Instead: show yourself that you care and will take good care of yourself. Doing that will release your feelings from your body.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 41,
                            title: '@TODO 41',
                            type: 'button',
                            background: mountainsNoCloud,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'A way to show yourself compassion is positive self-talk. Positive self-talk helps you challenge your inner critical voice. ',
                                                },
                                                {
                                                    text: 'Be ready for a short breathing and a positive sentence to yourself.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            text: 'Why is positive self-talk important? <span style="max-width: 300px; display: block; margin: 0 auto;"><a href="#" onClick="window.openExerciseHelp(event)">Learn more</a></span>',
                                            align: 'center',
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 42,
                            title: '@TODO 42',
                            type: 'button',
                            background: mountainsNoCloud,
                            button: {
                                text: 'I\'ve done that',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Sit, lie or stand in a comfortable position.',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                                {
                                                    line: true,
                                                },
                                                {
                                                    text: 'Keep your shoulders relaxed.',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 43,
                            title: '@TODO 43',
                            type: 'button',
                            background: mountainsNoCloud,
                            button: {
                                text: 'I\'m holding my breath',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Now inhale slowly and deeply through your nose. ',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                                {
                                                    line: true,
                                                },
                                                {
                                                    text: 'Hold your breath.',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 44,
                            title: '@TODO 44',
                            type: 'button',
                            background: mountainsNoCloud,
                            button: {
                                text: 'I\'ve done that',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Exhale slowly through your mouth.',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                                {
                                                    line: true,
                                                },
                                                {
                                                    text: 'Pause if you can or breathe normally after the exhale.',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 45,
                            title: '@TODO 45',
                            type: 'button',
                            background: mountainsNoCloud,
                            button: {
                                text: 'I\'ve done it 3 times',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Now you will do it again: Deep inhale through your nose, pause for just a second, exhale slowly through your mouth. ',
                                                },
                                                {
                                                    text: 'Try to feel the air you breathe. Does it feel cold when it comes through your nose and warm when it leaves your mouth? ',
                                                },
                                                {
                                                    text: 'Repeat 3 more times.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 46,
                            title: '@TODO 46',
                            type: 'button',
                            background: mountainsNoCloud,
                            button: {
                                text: 'I\'ve said it to myself',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Read the below sentences out loud to yourself (as loud as you dare) or inside your head if it is not possible for you right now. ',
                                                },
                                                {
                                                    text: 'It is challenging for many people. But talking to yourself is a strong tool to make yourself listen. ',
                                                },
                                                {
                                                    text: 'Say it as many times you like:',
                                                },
                                                {
                                                    text: '“Dear [$firstname], I love you. You are exactly like you need to be. I am here to support you. I have faith in you. I love you.”',
                                                    italic: true,
                                                    align: 'center',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 47,
                            title: '@TODO 47',
                            type: 'button',
                            background: picnic,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Exactly, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You are enough no matter what you do. What makes you special is all that is inside of you.<br />As you grew up, some things were easy, and some were hard. That is life.<br />But it does not change your value.<br />Humans are imperfect, fail and make mistakes – that is the beauty of life.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You are exactly like you need to be!',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                                {
                                                    text: 'Positive self-talk is important. <span style="max-width: 300px; display: block; margin: 0 auto;"><a href="#" onClick="window.openExerciseHelp(event)">Learn more</a></span>',
                                                    align: 'center',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 11,
                    name: 'Your parents and you',
                    category: 'Reflection',
                    color: '#E4856B',
                    icon: walking,
                    steps: [
                        {
                            id: 48,
                            title: '@TODO 48',
                            type: 'button',
                            background: loweredDownRiver,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Your parents and you',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'As you know, you learn a lot from your parents\' behavior.',
                                                },
                                                {
                                                    text: 'You have not only learned from how they view and treat you. You have also learned from how they view and treat themselves.',
                                                },
                                                {
                                                    text: 'Now you will reflect on your parent\'s self-worth.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 49,
                            title: 'Think of your parents or the ones that raised you. How do they treat themselves?',
                            type: 'input',
                            background: loweredDownRiver,
                            placeholder: "Write here: e.g. \n“My mom is extremely polite and not considering herself\", \n\"My dad think dreaming is silly\", \n\"They never show their feelings\", \n\"I don\'t know\"",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Use as much time as you need but do not leave the exercise. If you can\'t think of an answer, write “I can\'t remember”.',
                                            italic: true,
                                            size: 'small',
                                        },
                                        {
                                            text: 'Think of your parents or the ones that raised you. How do they treat themselves?<br />Do they treat themselves with care, respect, and love? Are they in balance?',
                                            renderHtml: true,
                                            bold: true,
                                        },
                                        {
                                            element: 'textarea',
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 50,
                            title: 'Which similarities do you see between how you treat yourself and how your parents treat themselves?',
                            type: 'input',
                            background: loweredDownRiver,
                            placeholder: "Write here: e.g. \n“I've learned to please other people\", \n“Dreaming is silly\", \n\"not to cry in public\", \n\"I don't know\"",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Which similarities do you see between how you treat yourself and how your parents treat themselves?',
                                            bold: true,
                                        },
                                        {
                                            text: 'Consider if you have learned something from them that you would prefer to let go of.',
                                        },
                                        {
                                            element: 'textarea',
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 51,
                            title: 'How did your parents show you love and that you were good enough?',
                            type: 'input',
                            background: loweredDownRiver,
                            placeholder: "Write here: e.g. \n“My parents said it to me\", \n\"I was kissed and hugged\", \n\"I feel it was shown\" \n\"I just knew\", \n\"They never showed love\", \n\"I don't know\"",
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'How did your parents show you love and that you were good enough?',
                                            bold: true,
                                        },
                                        {
                                            element: 'textarea',
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 52,
                            title: '@TODO 52',
                            type: 'button',
                            background: loweredRiverPeach,
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'You are doing great, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'For some, reflection can be tough.<br />For some, old grief appears. ',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'It is important to embrace what you feel.<br />When we open our hearts to ourselves and accept our feelings, it is soothing to our souls. ',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'How do you feel right now? Take a moment to listen to yourself.<br />If you need help to embrace your feelings, continue with the next exercise right away.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You are truly strong to be here!',
                                                    align: 'center',
                                                    bold: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 19,
                    name: 'Listen to yourself',
                    category: 'Reflection',
                    color: '#CCB696',
                    icon: breathingpeach,
                    steps: [
                        {
                            id: 53,
                            type: 'button',
                            title: '@TODO 53',
                            background: beach3,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Listen to yourself',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'This exercise is best to do as the last exercise in chapter 1. ',
                                                },
                                                {
                                                    text: 'When we reflect on our childhood, it may release old feelings that were never embraced. <br />If we don\'t pay attention to our feelings or bury them, they stay inside of us.<br />But if you embrace them and open your heart to yourself, you release yourself.<br />It is never too late to let go of old bad feelings.',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 54,
                            type: 'button',
                            title: '@TODO 54',
                            background: beach3,
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'This chapter has been focusing on your childhood because your childhood is fundamental to your self-worth.',
                                                },
                                                {
                                                    text: 'Some people find it difficult. Some feel they betray or let down their parents. Others get angry with their parents. And some do not feel anything. All feelings are welcome. Your feelings are welcome. ',
                                                },
                                                {
                                                    text: 'This exercise will end the chapter and help you listen to yourself.',
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 55,
                            title: 'Write a message to your parents or the ones who raised you. It is only for you and nothing they will see. ',
                            type: 'input',
                            background: beach3,
                            placeholder: "Write here: \n“Dear mum and dad …” \nor \n“I do not feel I can do this exercise right now”.",
                            button: {
                                text: 'I am done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    paddingX: true,
                                    content: [
                                        {
                                            text: 'Now think of yourself as a child.<br />Then write a message to your parents or the ones who raised you. It is only for you and nothing they will see.<br />Tell them honestly how it felt to be their daughter.<br />Be caring for yourself while you write it.<br />Know that you are right. Know that your feelings are ok.',
                                            renderHtml: true,
                                        },
                                        {
                                            text: 'Now, write your message here:',
                                            bold: true,
                                        },
                                        {
                                            text: '<a href="#" onclick="window.openExerciseHelp(event)">If you need inspiration, look at these examples ></a>',
                                            italic: true,
                                            underline: true,
                                            renderHtml: true,
                                        },
                                        {
                                            element: 'textarea',
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 56,
                            type: 'button',
                            title: '@TODO 56',
                            background: beach3,
                            button: {
                                text: 'Well Done',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Give yourself a hug, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You are practicing to listen and look after yourself.<br />Writing the message is a tool to help you with that and to release old feelings. ',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Now, take a minute and end this exercise by reading the below sentence out loud to yourself. ',
                                                },
                                                {
                                                    text: 'If you can’t say it out loud right now say it in your head or whisper it. ',
                                                },
                                                {
                                                    text: '“Dear [$firstname]. I love you for all you are. You are exactly like you need to be: perfect and lovely.<br />I will always be here for you and take good care of you.”',
                                                    renderHtml: true,
                                                    italic: true,
                                                    bold: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 57,
                            type: 'button',
                            title: '@TODO 57',
                            background: Beachpeach1,
                            button: {
                                text: 'Go to my reward',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Well Done, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You have done the first chapter in your journey.<br />You have reflected on how you have experienced love as a child. That is fundamental for how you treat yourself today.<br />It is your first step towards healthy self-worth. You are on your way. You have all you need to succeed. You have you!<br />Open your reward to end the chapter and get a great feeling.Your reward is boosting self-care.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You are worth loving for who you are!',
                                                    bold: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                    ],
                    help: {
                        title: 'Listen to yourself',
                        hideTitle: true,
                        slides: [
                            {
                                content: [
                                    {
                                        text: 'Example 1',
                                        color: '#707070',
                                        bold: true,
                                    },
                                    {
                                        line: true,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    },
                                    {
                                        text: 'Dear mom and dad,',
                                        italic: true,
                                    },
                                    {
                                        text: 'I know that you did the best you could and that your intentions were that other people should like me so I would mostly experience positivity in my life. But along the way, I concluded that I had to make everybody like me. That I had to think about others before thinking about myself. This means that I have forgotten about myself.',
                                        italic: true,
                                    },
                                ],
                            },
                            {
                                content: [
                                    {
                                        text: 'Example 2',
                                        color: '#707070',
                                        bold: true,
                                    },
                                    {
                                        line: true,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    },
                                    {
                                        text: 'Dear Dad',
                                        italic: true,
                                    },
                                    {
                                        text: 'You have taught me that it is wrong to be jealous and that I have to lose with the same spirit as if I win.<br/>But I have concluded that the feelings connected to jealousy and losing were wrong. <br>I felt wrong if I did not feel ok after having lost.<br />But my feelings were not wrong – I just had behaved properly. But it was OK to feel the way I did.',
                                        renderHtml: true,
                                        italic: true,
                                    },
                                ],
                            },
                            {
                                content: [
                                    {
                                        text: 'Example 3',
                                        color: '#707070',
                                        bold: true,
                                    },
                                    {
                                        line: true,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    },
                                    {
                                        text: 'Dear Mum',
                                        italic: true,
                                    },
                                    {
                                        text: 'I feel you have made fun of me to get attention yourself.',
                                        italic: true,
                                    },
                                    {
                                        text: 'It has made me feel as if I was a person that other people should not take seriously.<br>If I told you, you would say I am sensitive. It made me feel even more wrong.',
                                        renderHtml: true,
                                        italic: true,
                                    },
                                    {
                                        text: 'I know that I am not wrong, and I deeply feel you should have acted differently and taken better care of me.',
                                        italic: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            help: {
                title: 'Chapter 1',
                slides: [
                    {
                        content: [
                            {
                                text: 'In order to move forward, you need to look back and understand the origin of your self-evaluation. In that way, you can work with your critical beliefs about yourself.',
                            },
                            {
                                text: '<strong>In chapter 1 you will:</strong><br />- Get some questions to help you reflect on how you were loved as a child<br />- Examine which parts of you have been understood and not <br />- Gently reflect on your parents or the ones who raised you <br />- Get tools to learn to feel and listen to yourself',
                                renderHtml: true,
                            },
                        ],
                    },
                ],
            },
        },
        {
            id: 5,
            category: 'Reward',
            name: 'Reward',
            reward: true,
            requiredChapterId: 3,
            exercises: [
                {
                    id: 12,
                    reward: true,
                    name: 'Reward',
                    category: 'Boosting self-care',
                    color: '#85B7CD',
                    icon: Winnerpeach,
                    steps: [
                        {
                            id: 58,
                            title: '#58',
                            type: 'button',
                            background: Waterfall,
                            button: {
                                text: 'Start my positive shower',
                            },
                            content: [
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Reward to you, [$firstname]!',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'You have completed your first chapter, [$firstname]. Be proud!<br />You have decided to be here working on yourself.<br />It takes courage, curiosity, and will.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Your reward is a positive waterfall shower.<br />Take a minute and let yourself bathe in beautiful and positive sentences.<br />Just take them in.',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 59,
                            title: '#59',
                            type: 'button',
                            background: Peachshower,
                            button: {
                                text: 'I am strong',
                                buttonStyle: {
                                    fontSize: 22,
                                    padding: '20px 30px',
                                    color: '#85B6CC',
                                },
                            },
                            content: [
                                {
                                    text: 'Click on the sentence',
                                    align: 'center',
                                    size: 'small',
                                },
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 60,
                            title: '#60',
                            type: 'button',
                            background: Peachshower,
                            button: {
                                text: 'I can make good things happen',
                                buttonStyle: {
                                    fontSize: 22,
                                    padding: '20px 30px',
                                    color: '#85B6CC',
                                },
                            },
                            content: [
                                {
                                    text: 'Click on the sentence',
                                    align: 'center',
                                    size: 'small',
                                },
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 61,
                            title: '#61',
                            type: 'button',
                            background: Peachshower,
                            button: {
                                text: 'I can overcome my fear',
                                buttonStyle: {
                                    fontSize: 22,
                                    padding: '20px 30px',
                                    color: '#85B6CC',
                                },
                            },
                            content: [
                                {
                                    text: 'Click on the sentence',
                                    align: 'center',
                                    size: 'small',
                                },
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 62,
                            title: '#62',
                            type: 'button',
                            background: Peachshower,
                            button: {
                                text: 'I can be positive towards myself',
                                buttonStyle: {
                                    fontSize: 22,
                                    padding: '20px 30px',
                                    color: '#85B6CC',
                                },
                            },
                            content: [
                                {
                                    text: 'Click on the sentence',
                                    align: 'center',
                                    size: 'small',
                                },
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 63,
                            title: '#63',
                            type: 'button',
                            background: Peachshower,
                            button: {
                                text: 'I can progress',
                                buttonStyle: {
                                    fontSize: 22,
                                    padding: '20px 30px',
                                    color: '#85B6CC',
                                },
                            },
                            content: [
                                {
                                    text: 'Click on the sentence',
                                    align: 'center',
                                    size: 'small',
                                },
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'bottom',
                                    content: [
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 64,
                            title: '#64',
                            type: 'button',
                            background: Peachshower,
                            button: {
                                text: 'I am the greatest project I will ever work on!',
                                buttonStyle: {
                                    fontSize: 22,
                                    padding: '20px 30px',
                                    color: '#85B6CC',
                                },
                            },
                            content: [
                                {
                                    text: 'Click on the sentence',
                                    align: 'center',
                                    size: 'small',
                                },
                                {
                                    styleSubContent: false,
                                    verticalAlign: 'middle',
                                    content: [
                                        {
                                            element: 'button',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 65,
                            type: 'button',
                            background: Peachshower,
                            title: 'Yes, [$firstname], it is true!',
                            button: {
                                text: 'Well done',
                            },
                            content: [
                                {
                                    subContentStyle: {
                                        marginTop: 50,
                                    },
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    align: 'center',
                                                    image: Winnerpeach,
                                                    imageStyle: {
                                                        background: 'white',
                                                        borderRadius: '100%',
                                                        padding: 20,
                                                        height: 'auto',
                                                        width: 115,
                                                        marginTop: -70,
                                                        marginBottom: -30,
                                                    },
                                                },
                                                {
                                                    text: 'Yes, [$firstname], it is true!',
                                                    bold: true,
                                                    renderHtml: true,
                                                    align: 'center',
                                                    marginTop: 50,
                                                },
                                                {
                                                    text: 'You are strong. You can make good things happen. You can overcome your fear. You can be positive towards yourself. You can progress. And You are the greatest project you will ever work on!',
                                                },
                                                {
                                                    text: 'The positive sentences are your reward for being so persistent in the creation of your healthy self-worth. But the true reward comes from within. Over time you will feel all the above.',
                                                },
                                                {
                                                    text: 'You can give yourself well-earned respect<br />You can be proud of yourself, [$firstname]!<br />You have completed your first chapter on your beautiful self-worth journey.',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 66,
                            type: 'button',
                            background: Peachshower,
                            title: 'Dear test-user',
                            button: {
                                text: 'Go to your journey',
                                buttonStyle: {
                                    background: 'none',
                                    border: 'none',
                                    boxShadow: 'none',
                                },
                            },
                            content: [
                                {
                                    /*
                                    subContentStyle: {
                                        marginTop: 50,
                                    },
                                    */
                                    styleSubContent: false,
                                    verticalAlign: 'top',
                                    content: [
                                        {
                                            content: [
                                                {
                                                    text: 'Dear [$firstname],',
                                                    bold: true,
                                                    align: 'center',
                                                },
                                                {
                                                    text: 'My True Value is an app in progress.<br />Chapter 2 and Chapter 3 are in the making as you read this right now. They will be available to you throughout the autumn.',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Sign up here and be noted when the new chapters are available for you.',
                                                },
                                                {
                                                    text: '<a href="https://feelgood.ulties.co/signup/[$device]" target="_blank" style="background: #707070;border: 0;cursor: pointer;margin: 0 auto;display: block;padding: 12px 40px;color: white; text-decoration: none;box-shadow: 5px 10px 10px #00000029;text-align: center;border-radius: 7px;">Sign in here</a>',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'Did you like the app so far?',
                                                    bold: true,
                                                },
                                                {
                                                    text: 'Also, we would very much appreciate it if you are able to give us your feedback on your experience with the app so far. We are grateful for any comments and suggestions that may come.',
                                                },
                                                {
                                                    text: '<a href="https://feelgood.ulties.co/rate/[$device]" target="_blank" style="background: #707070;border: 0;cursor: pointer;margin: 0 auto;display: block;padding: 12px 40px;color: white; text-decoration: none;box-shadow: 5px 10px 10px #00000029;text-align: center;border-radius: 7px;">Feedback here</a>',
                                                    renderHtml: true,
                                                },
                                                {
                                                    text: 'You can give yourself well-earned respect<br />You can be proud of yourself, [$firstname]!<br />You have completed your first chapter on your beautiful self-worth journey.',
                                                    renderHtml: true,
                                                },
                                            ],
                                            marginBottom: 20,
                                        },
                                        {
                                            element: 'button',
                                        },
                                    ],
                                }
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 4,
            category: 'Chapter 2',
            name: 'Your requirements to yourself',
            requiredChapterId: 99999,
            requiredChapterText: 'Complete chapter 1 to unlock',
            exercises: [
                {
                    id: 5,
                    name: 'When do you think you have value?',
                    category: 'Reflection',
                    color: '#578B7E',
                    steps: [],
                },
                {
                    id: 13,
                    name: 'Breathing course 1',
                    category: 'Self-care',
                    color: '#578B7E',
                    steps: [],
                },
                {
                    id: 14,
                    name: 'Spot your self-worth patterns',
                    category: 'Reflection',
                    color: '#578B7E',
                    steps: [],
                },
                {
                    id: 15,
                    name: 'You are important',
                    category: 'Self-care',
                    color: '#578B7E',
                    steps: [],
                },
                {
                    id: 16,
                    name: 'Your shadows are important',
                    category: 'Reflection',
                    color: '#578B7E',
                    steps: [],
                },
                {
                    id: 17,
                    name: 'Breathing course 2',
                    category: 'Self-care',
                    color: '#578B7E',
                    steps: [],
                },
            ],
        },
        {
            id: 6,
            name: 'REWARD',
            category: 'REWARD',
            reward: true,
            requiredChapterId: 4,
            exercises: [
                {
                    id: 18,
                    name: 'Reward',
                    category: 'Well done',
                    reward: true,
                    steps: [],
                    color: '#578B7E',
                }
            ]
        },
        {
            id: 7,
            name: 'Chapter 3',
            category: 'A new way to treat yourself',
            requiredChapterId: 4,
            requiredChapterText: 'Complete chapter 2 to unlock.',
            exercises: [
                {
                    id: 60,
                    name: 'Your relation to you and your friends',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 20,
                    name: 'Your relation to your family',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 21,
                    name: 'Mindfulness',
                    category: 'Self-care',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 22,
                    name: 'Your love relations',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 23,
                    name: 'Your family',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 24,
                    name: 'Embrace who you are',
                    category: 'Self-care',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 25,
                    name: 'Your job',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 26,
                    name: 'Your body',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 27,
                    name: 'Waves',
                    category: 'Self-care',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 28,
                    name: 'Your home',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 29,
                    name: 'Your communication',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 30,
                    name: 'How to love yourself',
                    category: 'Self-care',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 31,
                    name: 'Your dreams',
                    category: 'Reflection',
                    steps: [],
                    color: '#578B7E',
                },
            ]
        },
        {
            id: 8,
            name: 'Chapter 4',
            category: 'Release yourself',
            requiredChapterId: 7,
            requiredChapterText: 'Complete chapter 3 to unlock.',
            exercises: [
                {
                    id: 32,
                    name: 'Forgive yourself',
                    category: 'Tool',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 33,
                    name: 'Give yourself power',
                    category: 'Self-care',
                    steps: [],
                    color: '#578B7E',
                },
            ]
        },
        {
            id: 9,
            name: 'REWARD',
            category: 'REWARD',
            reward: true,
            requiredChapterId: 8,
            exercises: [
                {
                    id: 34,
                    reward: true,
                    name: 'Reward',
                    category: 'Reward',
                    steps: [],
                    color: '#578B7E',
                },
            ]
        },
        {
            id: 10,
            name: 'Chapter 5',
            category: 'Meet your over-you!',
            requiredChapterId: 9,
            requiredChapterText: 'Complete chapter 4 to unlock.',
            exercises: [
                {
                    id: 35,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 36,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 37,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 38,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 39,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 40,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
            ]
        },
        {
            id: 11,
            name: 'Chapter 6',
            category: '',
            requiredChapterId: 10,
            requiredChapterText: 'Complete chapter 5 to unlock.',
            exercises: [
                {
                    id: 41,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 42,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 43,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 44,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 45,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 46,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 47,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 48,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 49,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
            ]
        },
        {
            id: 12,
            name: 'Chapter 7',
            category: '',
            requiredChapterId: 11,
            requiredChapterText: 'Complete chapter 6 to unlock.',
            exercises: [
                {
                    id: 50,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 51,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 52,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
                {
                    id: 53,
                    name: '',
                    category: '',
                    steps: [],
                    color: '#578B7E',
                },
            ]
        },
    ]
};

const propertyReducer: Reducer<IProperties, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PROPERTIES:
            return {
                ...state,
                ...action.properties
            };
        default:
            return state
    }
};

export default propertyReducer
