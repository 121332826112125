import {ActionTypes, Action} from 'mobile/Actions/actionConstants'
import {Reducer} from 'redux'

export const STATUSES = {
    NOT_REGISTERED: 'NOT_REGISTERED',
    REGISTERED: 'REGISTERED',
    FAILED: 'FAILED',
};

export interface INotificationState {
    status: string | null
    token: string | null
}

export function uuidv4() {
    // tslint:disable-next-line:only-arrow-functions
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // tslint:disable-next-line:no-bitwise
        const r = Math.random() * 16 | 0;
        // tslint:disable-next-line:triple-equals no-bitwise
        const v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const makeInitialState = () => {
    const defaultInitialState: INotificationState = {
        status: STATUSES.NOT_REGISTERED,
        token: null,
    };

    const storedStatus = localStorage.getItem('notificationStatus');
    const storedToken = localStorage.getItem('notificationToken');
    if (storedStatus) {
        switch (storedStatus) {
            case STATUSES.REGISTERED:
                if (storedToken) {
                    defaultInitialState.status = STATUSES.REGISTERED;
                    defaultInitialState.token = storedToken
                }
                break;
            case STATUSES.FAILED:
            case STATUSES.NOT_REGISTERED:
                defaultInitialState.status = storedStatus;
                break;
        }
    }

    return defaultInitialState;
}

export const initialState = makeInitialState();

const propertyReducer: Reducer<INotificationState, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_NOTIFICATION_STATE:
            const status = action.status;
            const token = action.token;

            // Save notificationStatus
            if (status) {
                localStorage.setItem('notificationStatus', status);
            } else {
                localStorage.removeItem('notificationStatus');
            }

            // Save notificationToken
            if (token) {
                localStorage.setItem('notificationToken', token);
            } else {
                localStorage.removeItem('notificationToken');
            }

            return {
                ...state,
                status,
                token,
            };
        case ActionTypes.RESET_NOTIFICATION_STATE:
            return {
                ...state,
                ...makeInitialState(),
            };
        default:
            return state
    }
};

export default propertyReducer
