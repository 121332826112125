import {IProperties, IUserData} from '../Types/appTypes'
import { ApiResponse} from '../Types/responseTypes'
import ApiHelper from "../Helpers/ApiHelper";
import {http, getHeaders} from "./http";

export const getProperties = (userData: IUserData, version: string, properties: IProperties): Promise<ApiResponse<IProperties>> => {
    // Call API
    return http(new Request(ApiHelper.path(`/properties`), {
        method: 'post',
        headers: getHeaders({
            version,
        }),
        body: JSON.stringify({
            version,
            userData,
            properties,
        }),
    }));
};
