import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'
// import {IHelpScreen} from "../Types/appTypes";

export interface IHelpModal {
    open: boolean
    // screens: IHelpScreen[] | null
}

const initialState: IHelpModal = {
    open: false,
    // screens: null,
};

const multiSelectModalReducer: Reducer<IHelpModal, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_HELP_MODAL:
            return {
                ...state,
                open: true,
                // screens: action.screens,
            };
        case ActionTypes.CLOSE_HELP_MODAL:
            return initialState;
        default:
            return state
    }
};

export default multiSelectModalReducer
