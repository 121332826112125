import React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core";

interface IProps {
    bottom?: boolean
    children?: any | any[]
    method?: 'margin' | 'padding'
    position?: 'relative' | 'fixed'
    top?: boolean
    width?: any
    backgroundColor?: any
}

export default (props: IProps) => {
    const constantBottom = 'constant(safe-area-inset-bottom)';
    const constantTop = 'constant(safe-area-inset-top)';
    const envBottom = 'env(safe-area-inset-bottom)';
    const envTop = 'env(safe-area-inset-top)';

    /*
    const constantBottom = 100;
    const constantTop = 30;
    const envBottom = 100;
    const envTop = 30;
    */

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            constantMargin: {
                marginBottom: props.bottom ? constantBottom : undefined,
                marginTop: props.top ? constantTop : undefined,
            },
            constantPadding: {
                paddingBottom: props.bottom ? constantBottom : undefined,
                paddingTop: props.top ? constantTop : undefined,
            },
            envMargin: {
                marginBottom: props.bottom ? envBottom : undefined,
                marginTop: props.top ? envTop : undefined,
            },
            envPadding: {
                paddingBottom: props.bottom ? envBottom : undefined,
                paddingTop: props.top ? envTop : undefined,
            },
            root: {
                display: 'block',
                position: props.position,
                width: props.width ? props.width : '100%',
                backgroundColor: props.backgroundColor,
            },
        }),
    );
    const classes = useStyles();

    let className = classes.root + ' ' + classes.constantMargin + ' ' + classes.envMargin;
    if (props.method === 'padding') {
        className = classes.root + ' ' + classes.constantPadding + ' ' + classes.envPadding;
    }

    return (
        <div className={className}>
            {props.children}
        </div>
    );
}
