import React from 'react'
import Screen from '../../Components/Screen';
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../../Reducers";
import {push} from "connected-react-router";

export interface IOwnProps {}

interface IStateProps {
    //
}

interface IDispatchProps {
    next: () => any
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const Step1 = (props: Props) => {
    const {next} = props;

    return (
        <div>
            <Screen name="Step2" showNavBar={false}>
                <p>Foo to the Feel Good prototype</p>
                <p>When you press "Start Feeling Good" you will land on the Welcome page in the onboarding.</p>
                <button onClick={next}>Start Feel Goode</button>
            </Screen>
        </div>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = () => ({
    //
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    next: () => {
        return dispatch(push('/intro/3'))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1)
