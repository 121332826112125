import { Reducer } from 'redux'
import { Action, ActionTypes } from 'mobile/Actions/actionConstants'

export interface ISnackbar {
    open: boolean
    message: string
}

const initialState: ISnackbar = {
    open: false,
    message: '',
};

const snackbarReducer: Reducer<ISnackbar, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_SNACKBAR:
            return {
                ...state,
                open: true,
                message: action.message,
            };
        case ActionTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                open: false,
            };
        default:
            return state
    }
};

export default snackbarReducer
