import { LocationChangeAction } from "connected-react-router"
import { IAddErrorAction, IRemoveErrorAction } from './errorActions'
import { IInternetOnline, IInternetOffline } from './internetConnectionActions'
import {IGetProperties, ISetProperties} from "./propertyActions";
import {IGetTranslations, ISetLocale, ISetTranslations} from "./localizationActions";
import {IRegisterDevice, IResetNotificationState, ISetNotificationState} from "./notificationActions";
import {ICloseSnackbar, IOpenSnackbar} from "./snackbarActions";
import {ICloseHelpModal, IOpenHelpModal} from "./helpModalActions";
import {ICloseExerciseModal, IOpenExerciseModal} from "./exerciseModalActions";
import {IResetUserData, ISetUserData} from "./userDataActions";

// We use the type-safe pattern from blog post by Drew Colthorp
// See: https://spin.atomicobject.com/2017/07/24/redux-action-pattern-typescript/

// This is our central registry of all the actions that the app knows about
// Aside from being used in defining actions, they are also used in reducers
// and sagas to avoid spelling errors
export enum ActionTypes {
    // Error dialog actions
    ADD_ERROR = "ADD_ERROR",
    REMOVE_ERROR = "REMOVE_ERROR",

    // Internet connection actions
    INTERNET_ONLINE = "INTERNET_ONLINE",
    INTERNET_OFFLINE = "INTERNET_OFFLINE",

    // Help modal actions
    OPEN_HELP_MODAL = "OPEN_HELP_MODAL",
    CLOSE_HELP_MODAL = "CLOSE_HELP_MODAL",

    // User actions
    LOGOUT_USER = "LOGOUT_USER",

    // Properties actions
    GET_PROPERTIES = "GET_PROPERTIES",
    SET_PROPERTIES = "SET_PROPERTIES",

    // Register device
    REGISTER_DEVICE = "REGISTER_DEVICE",
    SET_NOTIFICATION_STATE = "SET_NOTIFICATION_STATE",
    RESET_NOTIFICATION_STATE = "RESET_NOTIFICATION_STATE",

    // snackbar
    OPEN_SNACKBAR = 'OPEN_SNACKBAR',
    CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',

    // Localization actions
    SET_LOCALE = "SET_LOCALE",

    // Intro
    SET_INTRO_STEP = 'SET_INTRO_STEP',

    // Exercise
    OPEN_EXERCISE = "OPEN_EXERCISE",
    CLOSE_EXERCISE = "CLOSE_EXERCISE",

    // Exercise
    OPEN_EXERCISE_MODAL = "OPEN_EXERCISE_MODAL",
    CLOSE_EXERCISE_MODAL = "CLOSE_EXERCISE_MODAL",

    // Translations
    GET_TRANSLATIONS = "GET_TRANSLATIONS",
    SET_TRANSLATIONS = "SET_TRANSLATIONS",

    // User data
    SET_USER_DATA = "SET_USER_DATA",
    RESET_USER_DATA = "RESET_USER_DATA",

    // Actions used by external components
    EXTERNAL_ACTION = "__dummy_type_value__",  // don't import this, used for forcing default case in switches
}

/**
 * Represents any action that may be dispatched by libraries used by our app that we don't need to handle.
 */
export interface IExternalAction {
    type: ActionTypes.EXTERNAL_ACTION
}

export type Action =
    | LocationChangeAction  // dispatched by connected-react-router on browser navigation
    | IAddErrorAction
    | IRemoveErrorAction
    | IInternetOnline
    | IInternetOffline
    | IExternalAction
    | IGetProperties
    | ISetProperties
    | IRegisterDevice
    | ISetNotificationState
    | IResetNotificationState
    | IOpenSnackbar
    | ICloseSnackbar
    | ISetLocale
    | IGetTranslations
    | ISetTranslations
    | IOpenHelpModal
    | ICloseHelpModal
    | IOpenExerciseModal
    | ICloseExerciseModal
    | ISetUserData
    | IResetUserData
