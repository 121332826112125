import {IChapter, IExercise, IExerciseStep, IProperties, IUserData, IUserExercise} from "./Types/appTypes";

export function __(text: string, properties: IProperties, userData: IUserData) {
    // @ts-ignore
    const device = 'device' in window ? window.device.platform.toLowerCase() : 'browser';

    const user = {
        firstname: userData.user.firstname ?? 'Guest',
        device,
    };

    const exerciseValues = {};
    const completed = findCompleted(properties.chapters, userData.exercises, false, true, false);
    completed.forEach(({exercises}) => {
        exercises.forEach(({steps}) => {
            steps.forEach(({step, readableValue}) => {
                // exerciseValues[step.id + '.value'] = readableValue;
                // exerciseValues[step.id + '.title'] = step.title;
                // exerciseValues[step.id + '.title|uppercase'] = step.title.toUpperCase();
                exerciseValues[step.id] = readableValue;
            })
        })
    })

    const data = {
        ...user,
        ...exerciseValues,
    };

    for (const [key, value] of Object.entries(data)) {
        text = text.replace('[$' + key + ']', value);
        text = text.replace('[$' + key + '.lowercase]', toLowerCase(value));
    }

    return text;
}

function toLowerCase (text: any) {
    switch (typeof text) {
        case 'string': return text.toLowerCase();
        default: return text;
    }
}

export const findCompleted = (chapters: IChapter[], userDataExercises: IUserExercise[], includeNulls: boolean, includeInProgress: boolean, includeEmpty: boolean): Array<{ chapter: IChapter, exercises: Array<{ exercise: IExercise, finished: boolean, steps: Array<{ step: IExerciseStep, value: string | number | null, readableValue: string | number | null }> }> }> => {
    const completedChapters: Array<{ chapter: IChapter, exercises: Array<{ exercise: IExercise, finished: boolean, steps: Array<{ step: IExerciseStep, value: string | number | null, readableValue: string | number | null }> }> }> = [];

    chapters.forEach((chapter) => {
        const completedExercises: Array<{ exercise: IExercise, finished: boolean, steps: Array<{ step: IExerciseStep, value: string | number | null, readableValue: string | number | null }> }> = [];

        chapter.exercises.forEach((exercise) => {
            userDataExercises.forEach((userDataExercise) => {
                if (userDataExercise.id !== exercise.id) {
                    return
                }

                if (!userDataExercise.finished && !includeInProgress) {
                    return
                }

                const completedSteps: Array<{ step: IExerciseStep, value: string | number | null, readableValue: string | number | null }> = [];
                exercise.steps.forEach((step) => {
                    userDataExercise.steps.forEach((userDataExerciseStep) => {
                        if (step.id !== userDataExerciseStep.id) {
                            return
                        }

                        if (userDataExerciseStep.value === null && !includeNulls) {
                            return
                        }

                        let readableValue = userDataExerciseStep.value
                        if (step.type === 'selection' && "options" in step) {
                            step.options.forEach((option) => {
                                // @ts-ignore
                                if (userDataExerciseStep.value != option.id) {
                                    return
                                }

                                readableValue = option.text
                            })
                        }

                        completedSteps.push({
                            step,
                            value: userDataExerciseStep.value,
                            readableValue
                        })
                    })
                });

                if (completedSteps.length === 0 && !includeEmpty) {
                    return
                }

                completedExercises.push({
                    exercise,
                    finished: userDataExercise.finished,
                    steps: completedSteps
                })
            })
        })

        if (completedExercises.length === 0 && !includeEmpty) {
            return
        }

        completedChapters.push({
            chapter,
            exercises: completedExercises,
        })
    })

    return completedChapters;
}
