import { ActionTypes } from './actionConstants'
import {IProperties} from "../Types/appTypes";
import {initialState} from "../Reducers/properties";

export interface IGetProperties {
    readonly type: ActionTypes.GET_PROPERTIES
}

export interface ISetProperties {
    readonly type: ActionTypes.SET_PROPERTIES
    readonly properties: IProperties
}

export const getProperties = (): IGetProperties => ({
    type: ActionTypes.GET_PROPERTIES
});

export const setProperties = (properties: IProperties): ISetProperties => ({
    type: ActionTypes.SET_PROPERTIES,
    properties,
});

export const clearProperties = (): ISetProperties => ({
    type: ActionTypes.SET_PROPERTIES,
    properties: initialState,
});
