import React, {useEffect} from 'react'
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import RegisterNotification from "./RegisterNotification";
import {push} from "connected-react-router";

export interface IOwnProps {
    children?: any[] | any
}

interface IStateProps {
    introCompleted?: boolean
}

interface IDispatchProps {
    logout: () => any,
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const CompletedIntro: React.FunctionComponent<Props> = (props) => {
    const {introCompleted, logout} = props;

    useEffect(() => {
        // Ensure user is logged in
        if (!introCompleted) {
            logout();
            return;
        }
    });

    return (
        <RegisterNotification>
            {props.children}
        </RegisterNotification>
    );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ /**/ }) => ({
    introCompleted: true
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    logout: () => dispatch(push('/intro')),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompletedIntro);
