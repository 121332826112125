import { ActionTypes } from './actionConstants'
import {IUserData, IUserExercise} from "../Types/appTypes";

export interface ISetUserData {
    readonly type: ActionTypes.SET_USER_DATA
    readonly user: {
        uuid: string | null
        token: string | null
        firstname: string | null
        finishedIntro: boolean
    }
    readonly exercises: IUserExercise[]
}

export interface IResetUserData {
    readonly type: ActionTypes.RESET_USER_DATA
}

export const setUserData = (data: IUserData) => {
    // @ts-ignore
    localStorage.setItem('$_user', JSON.stringify(data.user));
    // @ts-ignore
    localStorage.setItem('$_exercises', JSON.stringify(data.exercises));

    return {
        type: ActionTypes.SET_USER_DATA,
        user: data.user,
        exercises: data.exercises,
    };
}

export const resetUserData = () => ({
    type: ActionTypes.RESET_USER_DATA,
})
