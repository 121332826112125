import React from 'react';
import {MapStateToProps, MapDispatchToPropsFunction, connect} from "react-redux";
import {createStyles, Drawer, makeStyles, Theme} from "@material-ui/core";
import {IStoreState} from '../Reducers'
import 'date-fns';
import {IHelp, IExerciseContent, IProperties, IUserData} from "../Types/appTypes";
import {__} from "../helpers";
import {useSwipeable} from "react-swipeable";

export interface IOwnProps {
    help: IHelp
    showButton?: boolean
    open?: boolean
    setOpen?: any
}

interface IStateProps {
    properties: IProperties
    userData: IUserData
}

interface IDispatchProps {
    //
}

type IProps =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({properties, userData}) => ({
    properties,
    userData,
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (/* dispatch */) => ({
    //
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            //
        },
        contentWrapper: {
            background: '#707070',
            width: '100vw',
            height: '100vh',
            display: 'block',
            padding: 12,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            lineHeight: 1.4,
            overflowY: 'auto',
        },
        bold: {
            fontWeight: 'bold',
        },
        italic: {
            fontStyle: 'italic',
        },
        sizeSmall: {
            fontSize: 12,
        },
        sizeNormal: {
            fontSize: 22,
        },
        sizeBig: {
            fontSize: 28,
        },
        alignLeft: {
            textAlign: 'left',
        },
        alignCenter: {
            textAlign: 'center',
        },
        alignRight: {
            textAlign: 'right',
        },
        title: {
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        button: {
            background: 'white',
            color: '#707070',
            padding: '12px 40px',
            display: 'block',
            borderRadius: 7,
            textAlign: 'center',
            cursor: 'pointer',
            margin: '0 auto',
            border: 0,
            '&:disabled': {
                background: 'gray',
            },
        },
        primaryButton: {
            background: '#90ED9B',
            color: 'white',
            padding: '12px 40px',
            display: 'block',
            borderRadius: 7,
            textAlign: 'center',
            cursor: 'pointer',
            margin: '0 auto',
            border: 0,
            '&:disabled': {
                background: '#707070',
            },
        },
        selectOption: {
            background: '#FFFFFF',
            color: '#707070',
            border: 0,
            padding: '10px 20px',
            display: 'block',
            borderRadius: 7,
            textAlign: 'center',
            cursor: 'pointer',
            marginBottom: 10,
            width: '100%',
            borderWidth: '1px',
            outline: 0,
            '&:hover': {
                background: '#E4E4E4',
            },
        },
        imageButtonWrapper: {
            maxHeight: 200,
            maxWidth: '100%',
        },
        countdown: {
            textAlign: 'center',
        },
        content: {
            color: '#696969',
        },
        verticalAlignMiddle: {
            display: 'table-cell',
            width: '100vw',
            height: 'calc(100vh - 75px)',
            verticalAlign: 'middle',
        },
        verticalAlignBottom: {
            display: 'table-cell',
            width: '100vw',
            height: 'calc(100vh - 75px)',
            verticalAlign: 'bottom',
            paddingBottom: 50,
        },
        verticalAlignTop: {
            display: 'table-cell',
            width: '100vw',
            height: 'calc(100vh - 75px)',
            verticalAlign: 'top',
        },
        subContent: {
            width: 'calc(100% - 20px)',
            margin: '0 auto',
            background: 'white',
            padding: '7px 20px',
            border: '5px solid white',
            borderRadius: 25,
            opacity: 0.9,
        },
        paddingX: {
            paddingRight: 25,
            paddingLeft: 25,
        },
        input: {
            background: 'white',
            borderRadius: 7,
            '&::placeholder': {
                color: '#E4E4E4',
            },
            width: '100%',
            padding: 10,
            border: 0,
            marginBottom: 10,
            marginTop: 10,
        },
    }),
);

const Help = (props: IProps) => {
    const {showButton, help, properties, userData} = props;
    let {open, setOpen} = props;

    if (typeof props.open === 'undefined' || typeof props.setOpen === 'undefined') {
        [open, setOpen] = React.useState(false)
    }

    const classes = useStyles();

    const content = (currentContent: IExerciseContent[]): any => {
        return (
            <div className={classes.content}>
                {currentContent && typeof currentContent !== 'undefined' ? currentContent.map((contentRow, key) => {
                    let className = '';

                    if (contentRow.bold) {
                        className += classes.bold + ' ';
                    }

                    if (contentRow.italic) {
                        className += classes.italic + ' ';
                    }

                    if (contentRow.size === 'big') {
                        className += classes.sizeBig + ' ';
                    }

                    if (contentRow.size === 'normal') {
                        className += classes.sizeNormal + ' ';
                    }

                    if (contentRow.size === 'small') {
                        className += classes.sizeSmall + ' ';
                    }

                    if (contentRow.align === 'left') {
                        className += classes.alignLeft + ' ';
                    }

                    if (contentRow.align === 'center') {
                        className += classes.alignCenter + ' ';
                    }

                    if (contentRow.align === 'right') {
                        className += classes.alignRight + ' ';
                    }

                    if (contentRow.verticalAlign === 'middle') {
                        className += classes.verticalAlignMiddle + ' ';
                    }

                    if (contentRow.verticalAlign === 'top') {
                        className += classes.verticalAlignTop + ' ';
                    }

                    if (contentRow.verticalAlign === 'bottom') {
                        className += classes.verticalAlignBottom + ' ';
                    }

                    if (contentRow.paddingX === true) {
                        className += classes.paddingX + ' ';
                    }

                    const subContent = () => {
                        if (typeof contentRow.content !== 'object') {
                            return ''
                        }

                        let subContentClass = classes.subContent;

                        if (contentRow.styleSubContent === false) {
                            subContentClass = '';
                        }

                        return (
                            <div className={subContentClass}>
                                {content(contentRow.content)}
                            </div>
                        )
                    }

                    const text = () => {
                        if (!contentRow.text) {
                            return
                        }

                        if (contentRow.renderHtml) {
                            return (
                                <p className={'render-html'} style={{
                                    color: contentRow.color,
                                }} dangerouslySetInnerHTML={{__html: __(contentRow.text, properties, userData)}}/>
                            )
                        }

                        return (
                            <p style={{
                                color: contentRow.color,
                            }}>{__(contentRow.text, properties, userData)}</p>
                        )
                    }

                    const line = () => {
                        if (typeof contentRow.line !== 'boolean' || contentRow.line === false) {
                            return
                        }

                        return (
                            <hr style={{
                                borderColor: contentRow.color ? contentRow.color : 'rgba(0, 0, 0, 0.87)',
                                borderWidth: 0,
                                borderTopWidth: 1,
                            }}/>
                        )
                    }

                    const dots = () => {
                        if (typeof contentRow.dots !== 'boolean' || !contentRow.dots) {
                            return
                        }

                        const stepNo = 2;
                        const stepAmount = 4;

                        const bars: boolean[] = [];
                        for (let i = 0; i < stepAmount; i++) {
                            bars.push(i <= stepNo);
                        }

                        return (
                            <div className={'bar-wrapper'} style={{
                                padding: '0 15px',
                                width: 'calc(100% - 20px)',
                                marginTop: -15,
                            }}>
                                {bars.map((status, barKey) => {
                                    return (
                                        <div style={{
                                            display: 'inline-block',
                                            width: 'calc(' + (100 / bars.length) + '% - 4px)',
                                            backgroundColor: status ? '#707070' : '#FFFFFF',
                                            margin: '0 2px',
                                            height: 4,
                                            borderRadius: 5,
                                        }} className={'single-bar'} key={barKey}/>
                                    )
                                })}
                            </div>
                        );
                    }

                    return (
                        <div key={key} className={className} style={{
                            marginBottom: contentRow.marginBottom,
                            marginTop: contentRow.marginTop,
                            width: contentRow.spaceX ? 'calc(100% - 35px)' : undefined,
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}>
                            {/*contentRow.image ? (<Image style={contentRow.imageStyle} marginTop={12} height={100}>{contentRow.image}</Image>) : ''*/}
                            {text()}

                            {line()}

                            {subContent()}

                            {dots()}
                        </div>
                    )
                }) : ''}
            </div>
        )
    }

    const closeIcon = () => {
        return (
            <p style={{
                color: '#707070',
                fontSize: 22,
                background: 'white',
                display: 'inline-block',
                width: 32,
                height: 32,
                textAlign: 'center',
                borderRadius: '100%',
                marginLeft: 10,
            }} onClick={() => {
                setOpen(false)
            }}>x</p>
        );
    }

    const rootAttributes = {
        // onClose: close,
        // @ts-ignore
        open,
        className: classes.root,
        style: {
            zIndex: 99999999,
        },
    };

    const slides = () => {
        return (
            <div style={{width: (help.slides.length * 100) + '%',
                            marginLeft: -(activeSlide * 100) + '%',  }}>
                {help.slides.map((slide, key) => {
                    return (
                        <div key={key} id={'slide-' + key} style={{
                            // opacity: activeSlide === key ? 1 : 0,
                            // display: activeSlide === key ? 'block' : 'none',
                            width: (100 / help.slides.length) + '%',
                            float: 'left',
                            // position: 'absolute',
                        }}>
                            {content(slide.content)}
                        </div>
                    )
                })}
            </div>
        )
    }

    const [activeSlide, setActiveSlide] = React.useState(0);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (activeSlide < help.slides.length - 1) {
                setActiveSlide(activeSlide + 1)
            }
        },
        onSwipedRight: () => {
            if (activeSlide > 0) {
                setActiveSlide(activeSlide - 1)
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const dotIndicator = () => {
        if (help.slides.length <= 1) {
            return
        }

        const bars: boolean[] = [];
        for (let i = 0; i < help.slides.length; i++) {
            bars.push(i === activeSlide);
        }

        return (
            <div className={'bar-wrapper'} style={{textAlign: 'center'}}>
                {bars.map((status, barKey) => {
                    return (
                        <div onClick={() => {
                            setActiveSlide(barKey)
                        }} style={{
                            display: 'inline-block',
                            width: 7,
                            backgroundColor: status ? '#707070' : '#E4E4E4',
                            margin: '0 2px',
                            height: 7,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }} className={'single-bar'} key={barKey}/>
                    )
                })}
            </div>
        );
    }

    const screens = () => {
        return (
            <div>
                <Drawer anchor="right" {...rootAttributes}>
                    <div className={classes.contentWrapper} style={{
                        // backgroundImage: step.background ? AssetHelper.cssUrl(step.background) : undefined,
                        // backgroundAttachment: 'fixed',
                    }} {...handlers}>
                        {closeIcon()}

                        <div className={classes.subContent}>
                            <p className={classes.bold} style={{
                                color: '#707070',
                                display: help.hideTitle === true ? 'none' : undefined,
                            }}>{help.title}</p>

                            <hr style={{
                                borderColor: 'rgba(0, 0, 0, 0.87)',
                                borderWidth: 0,
                                borderTopWidth: 1,
                                display: help.hideTitle === true ? 'none' : undefined,
                            }}/>

                            <div className={'slides'} style={{
                                overflow: 'hidden',
                            }}>{slides()}</div>

                            {dotIndicator()}
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }

    const questionmark = () => {
        if (showButton !== true) {
            return
        }

        return (
            <p onClick={() => {
                setOpen(true)
            }} style={{
                color: '#707070',
                position: 'absolute',
                top: -15,
                right: 0,
                fontSize: 20,
                border: '1px solid #707070',
                borderRadius: '100%',
                padding: '3px 8px',
            }}>?</p>
        )
    }

    return (
        <div>
            {questionmark()}

            {screens()}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Help)
