import { ActionTypes } from './actionConstants'

export interface IRegisterDevice {
    readonly type: ActionTypes.REGISTER_DEVICE
    readonly token: string|null
}

export const registerDevice = (token: string): any => ({
    type: ActionTypes.REGISTER_DEVICE,
    token,
});

export interface ISetNotificationState {
    readonly type: ActionTypes.SET_NOTIFICATION_STATE
    readonly status: string|null
    readonly token: string|null
}

export const setNotificationState = (status: string, token: string|null): ISetNotificationState => ({
    type: ActionTypes.SET_NOTIFICATION_STATE,
    status,
    token,
});

export interface IResetNotificationState {
    readonly type: ActionTypes.RESET_NOTIFICATION_STATE
}

export const resetNotificationState = (): any => ({
    type: ActionTypes.RESET_NOTIFICATION_STATE
});

