import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

type IMaterialUITextFieldProps = React.ComponentProps<typeof TextField>

interface IProps {
    children?: any[] | any
    id?: string
    label?: string | React.ReactElement
    onChange?: (event: any) => void
    placeholder?: string
    startAdornment?: any
    type?: 'text' | 'password' | undefined
    value?: string
    marginBottom?: any
    fontSize?: string | number
    autoFocus?: IMaterialUITextFieldProps['autoFocus']
}

export default function(props: IProps) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
                marginBottom: props.marginBottom,
                fontSize: props.fontSize ? props.fontSize : 10,
            }
        }),
    );

    const classes = useStyles();

    const rootAttr = {
        className: classes.root,
        id: props.id,
        label: props.label,
        onChange: props.onChange,
        placeholder: props.placeholder,
        type: props.type,
        value: props.value,
        autoFocus: props.autoFocus,
        InputProps: {
          startAdornment: props.startAdornment,
        }
    };

    return (
        <TextField {...rootAttr}>
            {props.children}
        </TextField>
    )
}
