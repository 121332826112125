import { ActionTypes } from './actionConstants'

export interface ISetLocale {
    readonly type: ActionTypes.SET_LOCALE
    readonly locale: string
}
export const setLocale = (locale: string): ISetLocale => ({
    type: ActionTypes.SET_LOCALE,
    locale,
});

export interface IGetTranslations {
    readonly type: ActionTypes.GET_TRANSLATIONS
    readonly locale: string
}
export const getTranslations = (locale: string): IGetTranslations => ({
    type: ActionTypes.GET_TRANSLATIONS,
    locale,
});

export interface ISetTranslations {
    readonly type: ActionTypes.SET_TRANSLATIONS
    readonly locale: string
    readonly translations: any
}
export const setTranslations = (locale: string, translations: any): ISetTranslations => ({
    type: ActionTypes.SET_TRANSLATIONS,
    locale,
    translations,
});
