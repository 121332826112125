import {IError} from 'mobile/Types/appTypes'
import { ActionTypes, Action } from 'mobile/Actions/actionConstants'
import { Reducer } from 'redux'

const initialState: IError = {
    text: '',
    time: 0,
};

const errorReducer: Reducer<IError, Action> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ERROR:
            return {
                ...state,
                ...action.payload
            };
        case ActionTypes.REMOVE_ERROR:
            return {
                ...state,
                text: ''
            };
        default:
            return state
    }
};

export default errorReducer
