import React, {useEffect} from 'react'
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import {push} from "connected-react-router";

export interface IOwnProps {
    children?: any[] | any
}

interface IStateProps {
    introComplete?: boolean
}

interface IDispatchProps {
    goHome: () => any
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const Guest: React.FunctionComponent<Props> = (props) => {
    const {goHome, introComplete} = props;

    useEffect(() => {
        // Ensure user is not logged in
        if (introComplete) {
            goHome();
            return;
        }
    });

    return props.children;
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ /**/ }) => ({
    introComplete: false
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    goHome: () => dispatch(push('/')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guest)
