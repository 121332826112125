import React, {useEffect} from 'react'
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../../Reducers";
import {push} from "connected-react-router";
import {logout as doLogout} from '../../Actions/userActions'

export interface IOwnProps {
    //
}

interface IStateProps {
    //
}

interface IDispatchProps {
    logout: () => any
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const Logout = (props: Props) => {
    const {logout} = props;

    useEffect(() => {
        logout();
        return;
    });

    return (
        <div />
    );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = () => ({
    //
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    logout: () => {
        dispatch(doLogout())
        return dispatch(push('/'))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
