import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {history, configureStore} from './store'
import {ThemeProvider} from 'shared/theme';
import LocalizationProvider from "./Components/LocalizationProvider";
import Loading from "./Components/Loading";
import App from "./Components/App";
// import { TransitionGroup, CSSTransition } from 'react-transition-group';

const store = configureStore();

ReactDOM.render(
    <ThemeProvider>
        <Provider store={store}>
            <Loading>
                <ConnectedRouter history={history}>
                    <LocalizationProvider>
                        <App />
                    </LocalizationProvider>
                </ConnectedRouter>
            </Loading>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Safari mobile does not respect cache headers when using the back-button to
// navigate to a page. This might fix it.
// See: https://stackoverflow.com/a/12652160/567000
window.onpageshow = (event: PageTransitionEvent) => {
    if (event.persisted) {
        window.location.reload()
    }
}
