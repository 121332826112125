// We use a discriminated union for the response type, so we don't end up with a ton of optional
// fields; this way we can do a single check on type and then know the exact shape

export enum ApiResponseTypes {
    EMPTY_SUCCESS = "EMPTY_SUCCESS",
    SUCCESS = "SUCCESS",
    API_ERROR = "API_ERROR",
    PROTOCOL_ERROR = "PROTOCOL_ERROR",
    NETWORK_ERROR = "NETWORK_ERROR",
}

interface IBaseResponse {
    responseCode: number
}

// We export all the subtypes to make it easier to create a test response of a given subtype by
// typing the variable to that subtype and letting auto-complete guide you

/**
 * The normal case response where we get some data in return for our request.
 * @template T The type of data in the response.
 */
export interface ISuccessResponse<T> extends IBaseResponse {
    type: ApiResponseTypes.SUCCESS,
    data: T
}

/**
 * Special case for requests where the server does not have data to send back on success.
 */
export interface IEmptySuccessResponse extends IBaseResponse {
    type: ApiResponseTypes.EMPTY_SUCCESS
    text: any
}

/**
 * All error responses from the API should have this shape.
 */
export interface IErrorResponse extends IBaseResponse {
    type: ApiResponseTypes.API_ERROR,
    status: string,
    details: string,
    displayMessage: string,
    link?: {
        href: string,
        rel: string
    }
}

/**
 * This response represents cases where we do not get JSON back.
 */
export interface IProtocolError extends IBaseResponse {
    type: ApiResponseTypes.PROTOCOL_ERROR
}

/**
 * To be used for when a request fails without getting a response.
 */
export interface IRequestError {
    type: ApiResponseTypes.NETWORK_ERROR,
    error: Error,
    message: string,
}

/**
 * The type of responses received from our API.
 * @template T The type of the data in a success response.
 */
export type ApiResponse<T> =
    | ISuccessResponse<T>
    | IEmptySuccessResponse
    | IErrorResponse
    | IProtocolError
    | IRequestError
