import { combineReducers, Reducer } from 'redux'
import { RouterState, connectRouter } from 'connected-react-router'
import { History } from 'history'
import {
  IError,
  IProperties,
  ILocalization, IUserData,
} from 'mobile/Types/appTypes'
import error from './error'
import internetConnection, { IInternetConnection } from './internetConnection'
import properties from './properties'
import localization from './localization'
import notificationState, {INotificationState} from "./notificationState";
import snackbar from './snackbar';
import helpModal, {IHelpModal} from "./helpModal";
import userData from "./userData";
import { Action } from 'mobile/Actions/actionConstants'

// This interface defines the shape of the Redux store
export interface IStoreState {
  error: IError
  notificationState: INotificationState
  helpModal: IHelpModal
  internetConnection: IInternetConnection
  properties: IProperties
  localization: ILocalization
  snackbar: {open: boolean, message: string}
  router: RouterState<any>
  userData: IUserData,
}

function errorHandlerReducerTransformer(reducer: Reducer<any, Action>): Reducer<any, Action> {
  return (state: any, action: Action) => {
    try {
      return reducer(state, action)
    }
    catch (e) {
      console.log('Generic Reducer Error', e)
      return state;
    }
  }
}

const getAllReducers = (history: History) => ({
  error,
  notificationState,
  helpModal,
  internetConnection,
  properties,
  localization,
  snackbar,
  router: connectRouter(history),
  userData,
});

type AllReducersType = ReturnType<typeof getAllReducers>;
const wrapReducersWithErrorHandling = (reducers: AllReducersType): Reducer<IStoreState> => {
  const mapped = Object.entries(reducers).reduce((reduction, [key, current]) => {
    return {
      ...reduction,
      [key]: errorHandlerReducerTransformer(current)
    }
  }, ({} as AllReducersType));
  return combineReducers(mapped);
}

export default (history: History): Reducer<IStoreState> => wrapReducersWithErrorHandling(getAllReducers(history));
