import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles';
import {MapStateToProps, connect, MapDispatchToPropsFunction} from "react-redux";
import { IStoreState } from '../Reducers'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { TransitionProps } from '@material-ui/core/transitions';
import {removeError} from "../Actions/errorActions";
// import {Zoom} from "@material-ui/core";
import Button from "shared/Components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {Roboto} from "shared/theme";

export interface IOwnProps {}
interface IDispatchProps {
    closeDialog: () => void
}
interface IStateProps {
    dialogText: string
}
type Props = IOwnProps & IStateProps & IDispatchProps

/*
const transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});
*/

const useStyles = makeStyles((theme) =>
    createStyles({
        button: {
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 10,
        },
        icon: {
            height: 'auto',
            marginRight: 10,
            width: '26px !important',
        },
        root: {
            display: 'none', // Disabled
            // zIndex: '999999 !important',
        },
        text: {
        	color: '#505050',
	        fontFamily: Roboto,
	        fontSize: 14,
        	letterSpacing: -0.25,
            textAlign: 'center',
        },
        title: {
        	color: '#505050',
            display: 'inline-block',
	        fontFamily: Roboto,
	        fontSize: 26,
        	fontWeight: 300,
            margin: 0,
            textAlign: 'center',
        },
        titleContainer: {
        	color: '#505050',
            textAlign: 'center',
        },
    }),
);

const ErrorDialog: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { closeDialog } = props;

    let { dialogText } = props;
    if (typeof dialogText === 'undefined') {
        dialogText = 'The web service failed without a error message.';
    }

    const open = dialogText.length > 0;

    return (
        <Dialog
            className={'z-index-99999 ' + classes.root}
            open={open}
            /*TransitionComponent={transition}*/
            keepMounted={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className={classes.titleContainer}>
                <FontAwesomeIcon icon={faExclamationTriangle} className={classes.icon} />
                <p className={classes.title}>Error</p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" className={classes.text}>
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button marginTop={5} marginBottom={15} marginLeft={10} marginRight={10} wide={true} onClick={closeDialog} type="submit">
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ error }) => ({
    dialogText: error.text
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    closeDialog: () => dispatch(removeError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)
