export const Path = (path: string) => {
    if (path[0] === '/') {
        return process.env.REACT_APP_API_ENDPOINT + path;
    }

    return path;
};

export default {
    path: Path
}
