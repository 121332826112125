import React, {useEffect} from 'react'
import Screen from '../Components/Screen';
import {createStyles, Theme, makeStyles} from "@material-ui/core";
import {IChapter, IExercise, IProperties, IUserData} from "../Types/appTypes";
import {connect, MapDispatchToPropsFunction, MapStateToProps} from "react-redux";
import {IStoreState} from "../Reducers";
import SafeArea from "../Components/SafeArea";
import Exercise from "../Components/Exercise";
import {__} from "../helpers";
import sky from '../assets/images/Sky.png'
import AssetHelper from "../Helpers/AssetHelper";
import Help from "../Components/Help";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {setUserData} from "../Actions/userDataActions";
import {uuidv4} from "../Reducers/notificationState";

export interface IOwnProps {
    //
}

interface IStateProps {
    properties: IProperties
    userData: IUserData
}

interface IDispatchProps {
    //
}

type Props =
    & IOwnProps
    & IStateProps
    & IDispatchProps

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notice: {
            background: '#ffffff',
            padding: '12px 30px',
            fontSize: 20,
            fontFamily: 'Montserrat',
            letterSpacing: 0,
            color: '#707070',
            borderBottomRightRadius: 25,
            borderBottomLeftRadius: 25,
            marginBottom: 50,
        },
        chapter: {
            margin: '12px 30px',
            borderTop: '1px solid #707070',
        },
        firstChapter: {
            borderTop: 0,
        },
        chapterName: {
            fontSize: 20,
            color: '#707070',
            fontWeight: 'bolder',
            margin: 0,
            marginTop: 7,
        },
        chapterCategory: {
            fontSize: 20,
            color: '#707070',
            margin: 0,
            marginTop: 30,
        },
        exerciseRow: {},
        exercise: {
            display: 'block',
            textAlign: 'center',
            cursor: 'pointer',
        },
        visible: {
            display: 'block',
            backgroundImage: AssetHelper.cssUrl(sky),
            backgroundAttachment: 'fixed',
        },
        hidden: {
            display: 'none',
        },
        noticeText: {
            paddingTop: 7,
            marginTop: 0,
            fontSize: 16,
            fontWeight: 300,
            fontStyle: 'italic',
            letterSpacing: 0,
            color: '#707070',
            lineHeight: 1.3,
        },
        helpModal: {
            //
        },
        chapterReward: {
            borderTop: 0,
        },
    }),
);

const Home = (props: Props) => {
    const {properties, userData} = props;

    if (userData.user.uuid === null) {
        userData.user.uuid = uuidv4();
        console.log(setUserData, userData)
    }

    console.log('UUID:', userData.user.uuid);

    const classes = useStyles();

    const chapterIds: number[] = [];
    const exerciseIds: number[] = [];
    const stepIds: number[] = [];
    const optionIds: number[] = [];
    properties.chapters.forEach((chapter) => {
        if (chapterIds.includes(chapter.id)) {
            console.warn('DUPLICATE CHAPTER ID: ' + chapter.id);
        }

        chapterIds.push(chapter.id)

        chapter.exercises.forEach((exercise) => {
            if (exerciseIds.includes(exercise.id)) {
                console.warn('DUPLICATE EXERCISE ID: ' + exercise.id);
            }

            exerciseIds.push(exercise.id)

            exercise.steps.forEach((step) => {
                if (stepIds.includes(step.id)) {
                    console.warn('DUPLICATE STEP ID: ' + step.id);
                }

                stepIds.push(step.id)

                if ("options" in step) {
                    step.options.forEach((option) => {
                        if (optionIds.includes(option.id)) {
                            console.warn('DUPLICATE OPTION ID: ' + option.id);
                        }

                        optionIds.push(option.id)
                    })
                }
            })
        })
    })

    console.info('CURRENTLY HIGHEST CHAPTER ID: ' + Math.max(...chapterIds))
    console.info('CURRENTLY HIGHEST EXERCISE ID: ' + Math.max(...exerciseIds))
    console.info('CURRENTLY HIGHEST STEP ID: ' + Math.max(...stepIds))
    console.info('CURRENTLY HIGHEST OPTION ID: ' + Math.max(...optionIds))

    useEffect(() => {
        // @ts-ignore
        if (typeof window.currentExercise === 'number') {
            return
        }

        if (!userData.user.finishedIntro) {
            if (document.getElementById('intro') !== null) {
                // @ts-ignore
                document.getElementById('intro').click();
            }
        }
    });

    let increment = 0;

    const exerciseChapterMap = {}
    const completedChapters = {}
    properties.chapters.forEach((chapter) => {
        completedChapters[chapter.id] = {
            completed: false,
            exercises: {},
        }

        chapter.exercises.forEach((exercise) => {
            exerciseChapterMap[exercise.id] = chapter.id
            completedChapters[chapter.id].exercises[exercise.id] = false
        })
    })
    userData.exercises.forEach((userDataExercise) => {
        completedChapters[exerciseChapterMap[userDataExercise.id]].exercises[userDataExercise.id] = userDataExercise.finished
    })
    Object.keys(completedChapters).forEach((chapterId) => {
        completedChapters[chapterId].completed = true

        Object.keys(completedChapters[chapterId].exercises).forEach((exerciseId) => {
            if (completedChapters[chapterId].exercises[exerciseId] === false) {
                completedChapters[chapterId].completed = false
            }
        })
    })

    // @TODO: Later get this from chapters, each chapter should be able to change this text.
    let introText = "This is your journey of concrete exercises to reach healthy self-worth. You have already done your first exercise.<br />Well done!";
    if (completedChapters[2].completed) {
        introText = 'Do at least 2 exercises every day to get the best result.';
    }

    return (
        <div className={!userData.user.finishedIntro ? classes.hidden : classes.visible}>
            <Screen name="Home" showNavBar={true} containerBackground={sky}>
                <div className={classes.notice}>
                    <SafeArea top={true}>
                        <p style={{marginBottom: 0, paddingBottom: 0}}>
                            <strong>{__('[$firstname]\'s Journey', properties, userData)}</strong></p>
                        <p className={classes.noticeText} dangerouslySetInnerHTML={{ __html: introText}} />
                    </SafeArea>
                </div>

                {properties.chapters.map((chapter: IChapter, chapterKey: number) => {
                    if (!chapter.intro) {
                        increment++;
                    }

                    let locked = typeof chapter.requiredChapterId === 'number';
                    Object.keys(completedChapters).forEach((chapterId) => {
                        if (chapter.requiredChapterId && chapter.requiredChapterId.toString() === chapterId && completedChapters[chapterId].completed) {
                            locked = false;
                        }
                    })

                    return (
                        <div key={chapterKey}
                             className={classes.chapter + ' ' + (increment === 1 ? classes.firstChapter : '') + ' ' + (chapter.intro ? classes.hidden : '') + ' ' + (chapter.reward ? classes.chapterReward : '')}>
                            <div style={{position: 'relative'}}>
                                {chapter.reward !== true ? (<p className={classes.chapterCategory}>{chapter.category}:</p>) : ''}
                                {chapter.reward !== true ? (<p className={classes.chapterName}>{chapter.name}</p>) : ''}

                                {locked && chapter.reward !== true ? (
                                    <p style={{
                                        textAlign: 'center',
                                        fontSize: 14,
                                        marginTop: 20,
                                        color: '#707070',
                                        fontWeight: 500,
                                    }}>
                                        <FontAwesomeIcon icon={faLock} style={{
                                            marginRight: 6,
                                        }}/>
                                        {chapter.requiredChapterText ? chapter.requiredChapterText : 'Complete previous chapter to unlock'}
                                    </p>
                                ) : ''}

                                {chapter.help ? (<Help help={chapter.help} showButton={true}/>) : ''}
                            </div>
                            <div>
                                <div className={classes.exerciseRow}>
                                    {chapter.exercises.map((exercise: IExercise, exerciseKey: number) => {
                                        const [exerciseOpen, setExerciseOpen] = React.useState(false);

                                        return (
                                            <div key={exerciseKey} className={classes.exercise}
                                                 style={{width: (100) + '%'}} onClick={() => {
                                                setExerciseOpen(true)
                                            }}
                                            >
                                                <Exercise
                                                    exercise={exercise}
                                                    locked={locked}
                                                    completed={locked ? false : completedChapters[chapter.id].exercises[exercise.id]}
                                                    exerciseOpen={exerciseOpen}
                                                    setExerciseOpen={setExerciseOpen}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}

                <SafeArea bottom={true}/>

            </Screen>
        </div>
    )
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({properties, userData}) => ({
    properties,
    userData
});

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (/*dispatch*/) => ({
    //
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)
